import { useQuery } from "@apollo/client";
import { PageableQueryVariables } from "../../models/graphql/pageable-query-variables.model";
import { GetUsersResponse } from "../../graphql/models/responses/get-users-response.type";
import { User } from "../../models/user.type";
import UsersQueries from "../../graphql/users/users-queries";

export const useUsersPageable = (variables: PageableQueryVariables<User>) => {
  return useQuery<GetUsersResponse>(UsersQueries.GetPageable, {
    variables,
  });
};
