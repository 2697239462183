/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { RecurringPatternModel } from "../../../../models/recurring-pattern.model";
import { DatePicker } from "@mui/x-date-pickers";
import { CYCLE_OPTIONS } from "../cycle-options";
import { DAY_OF_WEEK_OPTIONS } from "../day-options";
import { InfoOutlined } from "@mui/icons-material";

interface RecurringPatternInputProps {
  pattern: RecurringPatternModel;
  onChange: (recurringPattern: RecurringPatternModel) => void;
}

const normalizeDate = (dt: DateTime): DateTime => {
  return dt.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
};

const RecurringPatternInput = ({
  pattern,
  onChange,
}: RecurringPatternInputProps) => {
  const [now] = useState(DateTime.now());
  const [startTime, setStartTime] = useState<DateTime>(pattern.start_time);
  const [endTime, setEndTime] = useState<DateTime>(pattern.end_time);
  const [dayOfWeek, setDayOfWeek] = useState<number>(pattern.day_of_week);

  const [startDate, setStartDate] = useState<DateTime>(
    normalizeDate(pattern.start_date || DateTime.now())
  );
  const [endDate, setEndDate] = useState<DateTime | undefined>(
    pattern.end_date ? normalizeDate(pattern.end_date) : undefined
  );
  const [recurringEveryXWeeks, setRecurringEveryXWeeks] = useState<number>(
    pattern.recurring_every_x_weeks
  );
  const [isRecurring, setIsRecurring] = useState<boolean>(pattern.is_recurring);
  const [initialPattern, setInitialPattern] = useState<RecurringPatternModel>();

  useEffect(() => {
    const changedPattern = {
      ...pattern,
      start_time: startTime,
      end_time: endTime,
      day_of_week: dayOfWeek,
      start_date: startDate,
      end_date: endDate,
      is_recurring: isRecurring,
      recurring_every_x_weeks: recurringEveryXWeeks,
    };
    onChange(changedPattern);
  }, [
    startTime,
    endTime,
    dayOfWeek,
    startDate,
    endDate,
    isRecurring,
    recurringEveryXWeeks,
  ]);

  useEffect(() => {
    setInitialPattern(pattern);
  }, []);

  return (
    <Box className="container">
      <p>
        Is the class taking place periodically or one time on one or multiple
        days?
      </p>
      {isRecurring && (
        <Box display="flex" alignItems="center" gap="5px" marginBottom="10px">
          <InfoOutlined fontSize="small" />
          <Typography fontSize="small">
            We will create your occurences up to 60 days from today in advance.
          </Typography>
        </Box>
      )}
      <ToggleButtonGroup
        color="success"
        fullWidth
        exclusive
        disabled={!!pattern.id}
        value={isRecurring}
        onChange={(_, isRecurring) => {
          setIsRecurring(isRecurring);
          if (!isRecurring && !endDate) {
            setEndDate(now);
          }
          console.log("endDate", endDate);
          console.log("now", now);
          if (isRecurring && endDate === now) {
            setEndDate(undefined);
          }
        }}
      >
        <ToggleButton style={{ height: 56 }} value={true}>
          Multiple occurences
        </ToggleButton>
        <ToggleButton style={{ height: 56 }} value={false}>
          Single occurence
        </ToggleButton>
      </ToggleButtonGroup>

      <Box className="inputs-container">
        {isRecurring && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Box className="inputs-container">
                <h4>On which day of the week</h4>
                <Select
                  disabled={!!pattern.id}
                  value={dayOfWeek}
                  label="dayOfWeek"
                  onChange={(event: SelectChangeEvent<number>) => {
                    setDayOfWeek(event.target.value as number);
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7].map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {DAY_OF_WEEK_OPTIONS[index]}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="inputs-container">
                <h4>Recurring every x weeks</h4>
                <Select
                  disabled={!!pattern.id}
                  value={recurringEveryXWeeks}
                  label="recurringEveryXWeeks"
                  onChange={(event: SelectChangeEvent<number>) => {
                    setRecurringEveryXWeeks(event.target.value as number);
                  }}
                >
                  {CYCLE_OPTIONS.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.key}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <h4>Start and end date</h4>
              <p>
                The time period in which the class is occuring. Leave the end
                date empty if there is none for now. You will be able to set it
                at a later time.
              </p>
              {pattern.id && !initialPattern?.end_date && endDate && (
                <Box display="flex" alignItems="center" gap="5px">
                  <InfoOutlined fontSize="small" />

                  <Typography fontSize="small">
                    <Typography fontSize="small" fontWeight="bold">
                      Once you save an end date it cannot be changed anymore.
                    </Typography>
                    Editing the end date will remove all events from your class
                    calendar after the specified date. This means all events
                    beginning from{" "}
                    {endDate.plus({ days: 1 }).toFormat("dd.MM.yyyy")} will be
                    removed (if there are any).
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Start Date"
                disabled={!!pattern.id}
                value={startDate}
                disableMaskedInput
                onChange={(newValue) => {
                  if (newValue) {
                    const normalizedDate = normalizeDate(newValue);
                    setStartDate(normalizedDate);
                    if (endDate) {
                      const diff = endDate.diff(startDate, "days");
                      setEndDate(normalizedDate.plus({ days: diff.days }));
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} error={false} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date"
                disabled={pattern.is_end_date_final}
                value={endDate ?? null}
                minDate={startDate.plus({ days: 1 })}
                disableMaskedInput
                onChange={(newValue) => {
                  if (newValue) {
                    setEndDate(normalizeDate(newValue));
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} error={false} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <h4>Start and end time</h4>
              <p></p>
              {pattern.id &&
                (initialPattern?.start_time !== startTime ||
                  initialPattern.end_time !== endTime) && (
                  <Box display="flex" alignItems="center" gap="5px">
                    <InfoOutlined fontSize="small" />
                    <Typography fontSize="small">
                      Editing the start or end time will change all occurences
                      in your class calendar from now on.
                    </Typography>
                  </Box>
                )}
            </Grid>
            <Grid item xs={6} direction="column">
              <TimePicker
                label="Start Time"
                ampm={false}
                value={startTime}
                onChange={(newStartTime) => {
                  if (newStartTime) {
                    setStartTime(newStartTime);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} direction="column">
              <TimePicker
                label="End Time"
                ampm={false}
                value={endTime}
                onChange={(newEndTime) => {
                  if (newEndTime) {
                    setEndTime(newEndTime);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
        )}
        {!isRecurring && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <h4>Start Date & Time</h4>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Start Date"
                disabled={!!pattern.id}
                value={startDate}
                disableMaskedInput
                onChange={(newValue) => {
                  if (newValue) {
                    const normalizedDate = normalizeDate(newValue);
                    setStartDate(normalizedDate);
                    if (endDate) {
                      const diff = endDate.diff(startDate, "days");
                      setEndDate(normalizedDate.plus({ days: diff.days }));
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} error={false} />
                )}
              />
            </Grid>
            <Grid item xs={6} direction="column">
              <TimePicker
                label="Start Time"
                ampm={false}
                value={startTime}
                onChange={(newStartTime) => {
                  if (newStartTime) {
                    setStartTime(newStartTime);
                    const diff = endTime.diff(startTime, ["hours", "minutes"]);
                    setEndTime(startTime.plus(diff));
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <h4>End Date & Time</h4>
            </Grid>

            <Grid item xs={6}>
              <DatePicker
                label="End Date"
                disabled={pattern.is_end_date_final}
                value={endDate ?? null}
                minDate={startDate.plus({ days: 1 })}
                disableMaskedInput
                onChange={(newValue) => {
                  if (newValue) {
                    setEndDate(normalizeDate(newValue));
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} error={false} />
                )}
              />
            </Grid>
            <Grid item xs={6} direction="column">
              <TimePicker
                label="End Time"
                ampm={false}
                value={endTime}
                onChange={(newEndTime) => {
                  if (newEndTime) {
                    setEndTime(newEndTime);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default RecurringPatternInput;
