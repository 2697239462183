import { gql } from "@apollo/client";

export default class ClassMutations {
  public static Delete = gql`
    mutation Delete($id: uuid!) {
      delete_recurring_patterns(where: { class_id: { _eq: $id } }) {
        affected_rows
      }
      delete_class_events(where: { class_id: { _eq: $id } }) {
        affected_rows
      }
      delete_classes_by_pk(id: $id) {
        id
      }
    }
  `;
}
