import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import UrlSlugInput from "../../../../components/url-slug-input/url-slug-input";
import { GatheringType } from "../enums/gathering-type.enum";
import {
  useGetEventSlugSuggestionLazyQueryType,
  useIsEventSlugAvailableLazyQueryType,
} from "../../../../__generated___/gql";
import { setIsGlobaLoadingVisible } from "../../../../components/global-loading-overlay/global-loading-overlay";
import { useEffect } from "react";
import { GeneralClassInformationForm } from "../create-gathering";
import { useGetFirstError } from "../../../../hooks/forms/form-error-check";

interface GeneralGatheringInformationProps {
  type: GatheringType;
  form: GeneralClassInformationForm;
  onFormUpdate: (form: GeneralClassInformationForm) => void;
}

export default function GeneralGatheringInformation({
  type,
  form,
  onFormUpdate,
}: GeneralGatheringInformationProps) {
  const [isUrlSlugAvailableQuery] = useIsEventSlugAvailableLazyQueryType();
  const [getSlugSuggestionQuery] = useGetEventSlugSuggestionLazyQueryType();

  const nameFormFieldError = useGetFirstError(form?.name);
  const urlSlugFormFieldError = useGetFirstError(form?.url_slug);
  const descriptionFormFieldError = useGetFirstError(form?.description);

  const callGetSlugSuggestion = async () => {
    if (form.url_slug?.value || !form.name?.value) {
      return;
    }
    setIsGlobaLoadingVisible({ isOpen: true, text: "Loading suggestion" });
    const { data } = await getSlugSuggestionQuery({
      variables: { name: form.name?.value as string },
    });
    if (data?.get_event_slug_suggestion) {
      onFormUpdate({
        ...form,
        url_slug: {
          value: data?.get_event_slug_suggestion,
        },
      });
    }
    setIsGlobaLoadingVisible({ isOpen: false });
  };

  useEffect(() => {
    callGetSlugSuggestion();
  }, []);

  useEffect(() => {
    console.log("GeneralGatheringInformation");
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true} required>
          <InputLabel htmlFor="component-outlined">Name</InputLabel>
          <OutlinedInput
            id="component-outlined"
            label="Name"
            margin="dense"
            error={!!nameFormFieldError && form.name?.touched}
            value={form.name?.value}
            onBlur={() => {
              callGetSlugSuggestion();
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const name = event.target.value;
              onFormUpdate({
                name: {
                  value: name,
                  touched: true,
                  errors: {
                    ...(!name && { required: "Please provide a value" }),
                  },
                },
              });
            }}
          />
          <FormHelperText id="component-error-text">
            {nameFormFieldError
              ? nameFormFieldError
              : `A descriptive name for your ${type}`}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <UrlSlugInput
          required={true}
          urlSlug={form?.url_slug?.value}
          error={!!urlSlugFormFieldError && form.url_slug?.touched}
          onUrlSlugUpdate={(urlSlug: string) => {
            onFormUpdate({
              url_slug: {
                value: urlSlug,
                touched: true,
                errors: {
                  ...(!urlSlug && { required: "Please provide a value" }),
                },
              },
            });
          }}
          hint={
            urlSlugFormFieldError
              ? urlSlugFormFieldError
              : `Only lowercase letters, -, and numbers are allowed. In the future we will use the url slug in the url to your ${type}. (e.g. acroworld.de/events/${
                  form?.url_slug?.value
                    ? form?.url_slug.value
                    : "<your-url-slug>"
                })`
          }
          isUrlSlugAvailable={async (urlSlug: string) => {
            const queryResult = await isUrlSlugAvailableQuery({
              variables: {
                urlSlug,
              },
            });

            onFormUpdate({
              ...form,
              url_slug: {
                value: urlSlug,
                touched: true,
                errors: {
                  ...(!urlSlug && {
                    notAvailable: "Url Slug is not available",
                  }),
                },
              },
            });
            return queryResult.data?.is_event_slug_available ?? false;
          }}
        ></UrlSlugInput>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormControl fullWidth={true} required>
          <InputLabel htmlFor="description-input">Description</InputLabel>
          <OutlinedInput
            id="description-input"
            label="Description"
            margin="dense"
            error={!!descriptionFormFieldError && form.description?.touched}
            value={form.description?.value}
            multiline={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const description = event.target.value;
              onFormUpdate({
                description: {
                  value: description,
                  touched: true,
                  errors: {
                    ...(!description && { required: "Please provide a value" }),
                  },
                },
              });
            }}
            minRows={3}
            maxRows={5}
          />
          <FormHelperText id="description-input-helper-text">
            {descriptionFormFieldError
              ? descriptionFormFieldError
              : "Please provide a short description"}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
