import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Confirmation_Status_EnumType,
  useGetClassEventBookingsPageableQuery,
} from "../../__generated___/gql";
import { DateTime } from "luxon";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import { CURRENCY_OPTIONS } from "../../components/currency-selector/currency-selector";
import { ScrollContainer } from "../../components/scroll-container/scroll-container";

export const ClassEventBookings = () => {
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);
  const { classEventId } = useParams();

  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetClassEventBookingsPageableQuery({
    variables: {
      limit,
      offset,
      classEventId,
      ...(classEventId && {
        where: {
          class_event_id: { _eq: classEventId },
          status: { _eq: Confirmation_Status_EnumType.ConfirmedType },
        },
      }),
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  const startDate = DateTime.fromISO(
    data?.class_events_by_pk?.start_date ?? ""
  ).toUTC();
  const endDate = DateTime.fromISO(
    data?.class_events_by_pk?.end_date ?? ""
  ).toUTC();

  if (isQueryLoading) {
    return <LoadingOverlay />;
  }

  return (
    <ScrollContainer>
      <h1>Bookings</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Class: {data?.class_events_by_pk?.class?.name}
          </Typography>
          <Typography>
            Date: {startDate?.toFormat("EEEE, dd.MM.yyyy HH:mm")} -{" "}
            {endDate?.toFormat("HH:mm")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            Booked spaces:{" "}
            {data?.class_event_bookings_aggregate.aggregate?.count} /{" "}
            {data?.class_events_by_pk?.max_booking_slots}
          </Typography>
        </Grid>
        {data?.class_event_bookings.map((classEventBooking) => {
          return (
            <Grid item xs={12}>
              <Card>
                <Grid container spacing={1} padding={2}>
                  <Grid item xs={12} sm={3} display="flex" gap="5px">
                    <Typography fontWeight="bold">Booked by:</Typography>
                    <Typography>{classEventBooking.user?.name}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} display="flex" gap="5px">
                    <Typography fontWeight="bold">Option:</Typography>
                    <Typography>
                      {classEventBooking.booking_option?.title ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} display="flex" gap="5px">
                    <Typography fontWeight="bold">Paid:</Typography>
                    <Typography>
                      {classEventBooking.amount / 100}{" "}
                      {
                        CURRENCY_OPTIONS.find(
                          (currencyOption) =>
                            classEventBooking.currency === currencyOption.value
                        )?.symbol
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} display="flex" gap="5px">
                    <Typography fontWeight="bold">Booked at:</Typography>
                    <Typography>
                      {DateTime.fromISO(classEventBooking.created_at).toFormat(
                        "d MMMM yyyy | HH:mm"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </ScrollContainer>
  );
};
