import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
} from "@mui/material";
import { LoadingOverlay } from "../../../components/loading-overlay/loading-overlay";
import { useEffect, useState } from "react";
import { FileUtils } from "../../../lib/utils/file-utils";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import { Firebase } from "../../../lib/firebase";
import { UpdateResponse } from "../../../graphql/models/responses/update-response.model";
import { useNavigate, useParams } from "react-router-dom";
import { Teacher } from "../../../models/teacher.type";
import TeacherMutations from "../../../graphql/teachers/teacher-mutations";
import { TeacherView } from "../../../components/teachers/teacher/teacher";
import AddIcon from "@mui/icons-material/Delete";
import { useRoles } from "../../../hooks/use-roles";
import { RoleName } from "../../../enums/role.enum";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../../../components/global-notification-overlay/global-notification-overlay";
import { FormError } from "../../../models/form-error.model";
import { ScrollContainer } from "../../../components/scroll-container/scroll-container";

interface EditTeacherFormProps {
  isAdminView: boolean;
  queryDocumentNode: DocumentNode;
  updateDocumentNode: DocumentNode;
  onFinish?: () => void;
}

export const EditTeacherForm = ({
  isAdminView,
  queryDocumentNode,
  updateDocumentNode,
  onFinish,
}: EditTeacherFormProps) => {
  const navigate = useNavigate();
  const roles = useRoles();
  const { id } = useParams();
  const [
    mutateFunction,
    {
      data: updateMutationData,
      loading: isUpdateMutationLoading,
      error: updateMutationError,
    },
  ] =
    useMutation<UpdateResponse<"insert_teachers" | "delete_teachers_by_pk">>(
      updateDocumentNode
    );
  const {
    error: getTeacherError,
    loading: isGetTeacherByPkLoading,
    data: getTeacherByPkData,
  } = useQuery(queryDocumentNode, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  const [
    runDeleteMutation,
    {
      data: deleteMutationData,
      loading: isDeleteMutationLoading,
      error: deleteMutationError,
    },
  ] = useMutation<UpdateResponse<"delete_teachers_by_pk">>(
    TeacherMutations.Delete
  );

  const [value, setValue] = useState<Partial<Teacher>>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageSource, setProfileImageSource] = useState<string>();
  const [imageSources, setImageSources] = useState<string[]>([]);
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  useEffect(() => {
    setIsLoading(
      isImageUploadLoading ||
        isUpdateMutationLoading ||
        isGetTeacherByPkLoading ||
        isDeleteMutationLoading
    );
  }, [
    isImageUploadLoading,
    isUpdateMutationLoading,
    isGetTeacherByPkLoading,
    isDeleteMutationLoading,
  ]);

  useEffect(() => {
    setGlobalErrorNotification(
      updateMutationError?.message ||
        getTeacherError?.message ||
        deleteMutationError?.message ||
        ""
    );
  }, [updateMutationError, getTeacherError, deleteMutationError]);

  useEffect(() => {
    if (updateMutationData || deleteMutationData) {
      setGlobalSuccessNotification("Success!");
      if (onFinish) {
        onFinish();
      }
    }
  }, [navigate, updateMutationData, deleteMutationData]);

  useEffect(() => {
    if (error) {
      setGlobalErrorNotification(error);
    }
  }, [error]);

  return (
    <ScrollContainer>
      <LoadingOverlay isOpen={isLoading} />
      <Dialog
        open={isDeleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Delete teacher "{value?.name}"?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this teacher? The following
            dependend object will also be deleted:
            <ul>
              <li>The events where this is the only owner</li>
              <li>The event occurences where this is the only owner</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flex="1" justifyContent="space-between">
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                runDeleteMutation({
                  variables: {
                    id: value?.id,
                  },
                });
              }}
              variant="contained"
              color="error"
              autoFocus
            >
              Yes, delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>
          {roles.includes(RoleName.AdminUser)
            ? "Update Teacher"
            : "Update your profile"}
        </h1>
        {roles.includes(RoleName.AdminUser) && (
          <Fab
            onClick={() => {
              setIsDeleteDialogOpen(true);
            }}
            variant="extended"
            color="error"
            aria-label="add"
          >
            <AddIcon />
            Delete Teacher
          </Fab>
        )}
      </Box>

      <Box className="inputs-container">
        {!isLoading && getTeacherByPkData && (
          <TeacherView
            value={getTeacherByPkData.teachers_by_pk}
            isEditView={true}
            isAdminView={roles.includes(RoleName.AdminUser)}
            onNewProfileImageSet={(imageAsBase64: string | undefined) => {
              setProfileImageSource(imageAsBase64);
            }}
            onNewImagesSet={(imageSources: string[]) => {
              setImageSources(imageSources);
            }}
            onTeacherUpdated={(newValue: Partial<Teacher>) => {
              setValue({ ...value, ...newValue });
            }}
            addFormError={(key: string, message: string) => {
              setFormErrors([...formErrors, { key, message }]);
            }}
            removeFormError={(key: string) => {
              const index = formErrors.findIndex((error) => error.key === key);
              if (index > -1) {
                formErrors.splice(index, 1);
                setFormErrors([...formErrors]);
              }
            }}
          ></TeacherView>
        )}
        <Button
          fullWidth
          variant="contained"
          onClick={async () => {
            if (!value) {
              return;
            }
            if (!value.user) {
              setError("Set a user");
              return;
            }
            if (!value?.description) {
              setGlobalErrorNotification("Set a description");
              return;
            }
            if (formErrors.length > 0) {
              setGlobalErrorNotification(formErrors[0].message);
              return;
            }
            const imagesInput: {
              is_profile_picture: boolean;
              image: { data: { url: string } };
            }[] = [];
            try {
              setIsImageUploadLoading(true);
              let uploadedProfileImageUrl;
              if (
                profileImageSource &&
                FileUtils.isBase64(profileImageSource)
              ) {
                const profileImageAsBlob = await FileUtils.convertBase64ToBlob(
                  profileImageSource
                );
                uploadedProfileImageUrl = await Firebase.uploadFile(
                  profileImageAsBlob
                );
              } else {
                uploadedProfileImageUrl = profileImageSource;
              }

              const uploadedFilesUrls: string[] = [];
              for (let i = 0; i < imageSources.length; i++) {
                let imageUrl;
                const imageSource = imageSources[i];
                if (FileUtils.isBase64(imageSource)) {
                  const imageAsBlob = await FileUtils.convertBase64ToBlob(
                    imageSource
                  );
                  imageUrl = await Firebase.uploadFile(imageAsBlob);
                } else {
                  imageUrl = imageSource;
                }
                uploadedFilesUrls.push(imageUrl);
              }

              if (uploadedProfileImageUrl) {
                imagesInput.push({
                  is_profile_picture: true,
                  image: { data: { url: uploadedProfileImageUrl } },
                });
              }

              if (uploadedFilesUrls.length > 0) {
                imagesInput.push(
                  ...uploadedFilesUrls.map((url) => ({
                    is_profile_picture: false,
                    image: { data: { url: url } },
                  }))
                );
              }
              const variables = {
                ...value,
                name: value.name,

                images: imagesInput,
                levels: value?.teacher_levels?.map((teacher_level) => ({
                  level_id: teacher_level.level.id,
                })),
              };
              const createVariables = {
                variables: {
                  ...variables,
                  userId: value?.user?.id,
                },
              };
              mutateFunction(createVariables);
            } catch (e: any) {
              setError(e.message);
            } finally {
              setIsImageUploadLoading(false);
            }
          }}
        >
          Update
        </Button>
      </Box>
    </ScrollContainer>
  );
};
