import "./App.scss";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  FieldFunctionOptions,
} from "@apollo/client";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import { CreateTeacherForm } from "./screens/teachers/create-teacher-form/create-teacher-form";
import { CreateClassForm } from "./screens/classes/create-class-form/create-class-form";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Environment } from "./environment";
import jwtDecode from "jwt-decode";
import { AcroworldJwt } from "./models/acroworld-jwt.type";
import { RoleName } from "./enums/role.enum";
import { Teachers } from "./components/teachers/teachers";
import { AdminClassesView } from "./screens/admin-classes-view/admin-classes-view";
import { EditClassForm } from "./screens/classes/edit-class-form/edit-class-form";
import { EditTeacherForm } from "./screens/teachers/edit-teacher-form/edit-teacher-form";
import { useRoles } from "./hooks/use-roles";
import { ClassEvents } from "./screens/class-events/class-events";
import { DateTime } from "luxon";
import GlobalNotificationOverlay from "./components/global-notification-overlay/global-notification-overlay";
import GlobalLoadingOverlay from "./components/global-loading-overlay/global-loading-overlay";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { UsersView } from "./components/users/users";
import { ProtectedRoute } from "./components/protected-route/protected-route";
import Register from "./screens/register/register";
import {
  GetTeacherByPkAsAdminUserDocumentType,
  GetTeacherByPkAsTeacherUserDocumentType,
  UpdateTeacherAsAdminUserDocumentType,
  UpdateTeacherAsTeacherUserDocumentType,
  useGetEventByPkAsAdminUserLazyQueryType,
  useGetEventByPkAsTeacherUserLazyQueryType,
  useUpsertEventAsAdminUserMutation,
  useUpsertEventAsTeacherUserMutation,
} from "./__generated___/gql";
import { AdminEvents } from "./components/events/admin-events-view/admin-events-view";
import { CreateEventForm } from "./screens/events/create-event/create-event";
import { InviteList } from "./screens/invites/invites";
import TokenCallback from "./screens/token-callback/token-callback";
import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { ClassEventBookings } from "./screens/class-event-bookings/class-event-bookings";
import { SingleClassEvent } from "./components/single_class-event/single-class-event";
import { TeacherClassesView } from "./screens/teacher-classes-view/teacher-classes-view";
import DeleteAccount from "./components/delete-account/delete-account";
import StripeCallback from "./screens/stripe-callback/stripe-callback";
import VerifyEmailCallback from "./screens/verify-email-callback/verify-email-callback";
import { TeacherEvents } from "./screens/events/teacher-events-view/teacher-events-view";
import { AllBookings } from "./screens/bookings/all-bookings/all-bookings";
import CreateGathering from "./screens/gatherings/create-gathering/create-gathering";
import Dashboard from "./screens/Dashboard/Dashboard";
import Login from "./screens/Login/Login";

if (!Environment.isDevelopment) {
  Sentry.init({
    dsn: "https://b1e369d29354245bfc5051c4cf752e0a@o4506016337690624.ingest.sentry.io/4506016340508672",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [process.env.REACT_APP_API_URL ?? ""],
      }),
      captureConsoleIntegration({
        levels: ["error"],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

console.log("process.env", process.env);

const App = () => {
  const navigate = useNavigate();
  const roles = useRoles();
  const isAdminUser = roles.includes(RoleName.AdminUser);

  return (
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/token-callback" element={<TokenCallback />} />
      <Route path="/delete-account" element={<DeleteAccount />} />

      <Route path="/app" element={<ProtectedRoute />}>
        <Route path="/app" element={<Dashboard />} />
        <Route path="gatherings/create" element={<CreateGathering />} />

        <Route path="stripe-callback" element={<StripeCallback />} />
        <Route
          path="email-verification-callback"
          element={<VerifyEmailCallback />}
        />

        {/* Classes */}
        <Route
          path="classes"
          element={isAdminUser ? <AdminClassesView /> : <TeacherClassesView />}
        />
        <Route path="classes/create" element={<CreateClassForm />} />
        <Route path="classes/:id" element={<EditClassForm />} />

        {/* ClassEvents */}
        <Route path="classes/:classId/events" element={<ClassEvents />} />

        <Route
          path="classes/:classId/events/:classEventId/bookings"
          element={<ClassEventBookings />}
        />

        {/* ClassEvent */}
        <Route path="event/:classEventId" element={<SingleClassEvent />} />

        {/* Events*/}
        <Route
          path="events"
          element={isAdminUser ? <AdminEvents /> : <TeacherEvents />}
        />
        <Route
          path="events/create"
          element={
            <CreateEventForm
              useLazyQuery={
                isAdminUser
                  ? useGetEventByPkAsAdminUserLazyQueryType
                  : useGetEventByPkAsTeacherUserLazyQueryType
              }
              useMutation={
                isAdminUser
                  ? useUpsertEventAsAdminUserMutation
                  : useUpsertEventAsTeacherUserMutation
              }
            />
          }
        />
        <Route
          path="events/:id"
          element={
            <CreateEventForm
              useLazyQuery={
                isAdminUser
                  ? useGetEventByPkAsAdminUserLazyQueryType
                  : useGetEventByPkAsTeacherUserLazyQueryType
              }
              useMutation={
                isAdminUser
                  ? useUpsertEventAsAdminUserMutation
                  : useUpsertEventAsTeacherUserMutation
              }
            />
          }
        />

        {/* Teachers */}
        <Route path="teachers" element={<Teachers />} />
        <Route
          path="teachers/create"
          element={
            <CreateTeacherForm
              onFinish={() => {
                navigate("/app/teachers");
              }}
            />
          }
        />
        <Route
          path="teachers/:id"
          element={
            <EditTeacherForm
              isAdminView={isAdminUser}
              queryDocumentNode={
                isAdminUser
                  ? GetTeacherByPkAsAdminUserDocumentType
                  : GetTeacherByPkAsTeacherUserDocumentType
              }
              updateDocumentNode={
                isAdminUser
                  ? UpdateTeacherAsAdminUserDocumentType
                  : UpdateTeacherAsTeacherUserDocumentType
              }
              onFinish={() => {
                if (isAdminUser) {
                  navigate("/app/teachers");
                } else if (roles.includes(RoleName.TeacherUser)) {
                  navigate("/");
                }
              }}
            />
          }
        />

        {/* Invites*/}
        <Route path="invites" element={<InviteList />} />

        {/* Invites*/}
        <Route path="bookings" element={<AllBookings />} />

        {/* Users */}
        <Route path="users" element={<UsersView />} />
      </Route>
    </Routes>
  );
};

function Root() {
  const graphqlUrl = process.env.REACT_APP_API_URL;
  const httpLink = createHttpLink({
    uri: graphqlUrl,
  });

  console.log("Environment.isDevelopment", Environment.isDevelopment);
  console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
  console.log("graphqlUrl", graphqlUrl);

  const authLink = setContext((request, { headers }) => {
    if (
      request.operationName &&
      ["LoginWithRefreshToken"].includes(request.operationName)
    ) {
      return {};
    }
    const token = localStorage.getItem("jwt-token");
    const parsedToken = JSON.parse(token as string);
    let updatedHeaders = { ...headers };
    if (parsedToken) {
      const decodedToken = jwtDecode<AcroworldJwt>(parsedToken);
      const allowedRoles =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];
      const roleWithHighestPriveleges =
        allowedRoles.find((role) => role === RoleName.AdminUser) ||
        allowedRoles.find((role) => role === RoleName.TeacherUser) ||
        RoleName.User;
      updatedHeaders["authorization"] = `Bearer ${parsedToken}`;
      updatedHeaders["x-hasura-role"] = roleWithHighestPriveleges;
    }
    console.log("updatedHeaders", updatedHeaders);
    return {
      headers: { ...updatedHeaders },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        users: {
          fields: {
            created_at: {
              read(created_at: string) {
                return DateTime.fromISO(created_at);
              },
            },
          },
        },
        created_invites: {
          fields: {
            created_at: {
              read(created_at: string) {
                return DateTime.fromISO(created_at);
              },
            },
          },
        },
        recurring_patterns: {
          fields: {
            start_time: {
              read(start_time: string) {
                return DateTime.fromISO(start_time).toUTC();
              },
            },
            end_time: {
              read(end_time: string) {
                return DateTime.fromISO(end_time).toUTC();
              },
            },
            start_date: {
              read(start_date: string, args) {
                console.log("start_date", start_date);
                const createdAt = args.readField("created_at") as string;
                return DateTime.fromISO(
                  start_date ? start_date : createdAt
                ).toUTC();
              },
            },
            end_date: {
              read(end_date: string) {
                return end_date ? DateTime.fromISO(end_date).toUTC() : null;
              },
            },
            created_at: {
              read(created_at: string) {
                return DateTime.fromISO(created_at).toUTC();
              },
            },
            is_end_date_final: {
              read(_, options: FieldFunctionOptions) {
                return !!options.readField("end_date");
              },
            },
          },
        },
        events: {
          fields: {
            start_date: {
              read(start_date: string) {
                return DateTime.fromISO(start_date);
              },
            },
            end_date: {
              read(end_date: string) {
                return DateTime.fromISO(end_date);
              },
            },
          },
        },
        class_event_bookings: {
          fields: {
            created_at: {
              read(created_at: string) {
                return DateTime.fromISO(created_at).toUTC();
              },
            },
          },
        },
        class_events: {
          fields: {
            start_date: {
              read(start_date: string) {
                return DateTime.fromISO(start_date).toUTC();
              },
            },
            end_date: {
              read(end_date: string) {
                return DateTime.fromISO(end_date).toUTC();
              },
            },
          },
        },
      },
    }),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
          <ThemeProvider theme={theme}>
            <GlobalNotificationOverlay />
            <GlobalLoadingOverlay />
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default Root;
