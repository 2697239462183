import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

interface UrlSlugInputProps {
  required?: boolean;
  urlSlug?: string;
  hint?: string;
  error?: boolean;
  urlSlugSuggestion?: string;
  onUrlSlugUpdate: (urlSlug: string) => void;
  isUrlSlugAvailable: (urlSlug: string) => Promise<boolean>;
}
export default function UrlSlugInput({
  required,
  urlSlug,
  hint,
  error,
  onUrlSlugUpdate,
  isUrlSlugAvailable,
}: UrlSlugInputProps) {
  const [initialUrlSlug] = useState(urlSlug);
  const [urlSlugInput, setUrlSlugInput] = useState(urlSlug);
  const [isLoading, setIsLoading] = useState(false);
  const [isSlugAvailable, setIsSlugAvailable] = useState(urlSlug !== "");
  const [showUrlSlugResult, setShowUrlSlugResult] = useState(urlSlug !== "");

  useEffect(() => {
    setUrlSlugInput(urlSlug);
  }, [urlSlug]);

  useEffect(() => {
    setShowUrlSlugResult(urlSlugInput !== initialUrlSlug && !isLoading);
  }, [urlSlugInput, isSlugAvailable, isLoading]);

  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        type="text"
        label={`Url Slug ${required ? "*" : ""}`}
        value={urlSlugInput || ""}
        error={(!isSlugAvailable && showUrlSlugResult) || error}
        fullWidth
        helperText={hint}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-evenly"
                gap={1}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    {showUrlSlugResult && (
                      <>
                        {isSlugAvailable && (
                          <Tooltip title="Url slug can be used">
                            <CheckIcon color="success" />
                          </Tooltip>
                        )}
                        {!isSlugAvailable && (
                          <Tooltip title="Url slug is already used">
                            <ClearIcon color="error" />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </InputAdornment>
          ),
        }}
        onChange={async (event) => {
          const inputValue = event.target.value;
          const formattedValue = inputValue
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, ""); // Remove characters not allowed
          onUrlSlugUpdate(formattedValue);
          setUrlSlugInput(formattedValue);
          const urlSlugChanged = initialUrlSlug !== formattedValue;
          if (urlSlugChanged) {
            setIsLoading(true);
            const isAvailable = await isUrlSlugAvailable(formattedValue);
            setIsSlugAvailable(isAvailable);
            setIsLoading(false);
          } else {
            setIsSlugAvailable(true);
          }
        }}
      />
    </FormControl>
  );
}
