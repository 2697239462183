import { LoadingButton } from "@mui/lab";
import { useGetStripeLoginLinkLazyQueryType } from "../../__generated___/gql";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useState } from "react";

export const OpenStripeDashboardButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getStripeLoginLink] = useGetStripeLoginLinkLazyQueryType({
    fetchPolicy: "network-only",
  });
  return (
    <LoadingButton
      loading={isLoading}
      loadingPosition="start"
      variant="outlined"
      startIcon={<AttachMoneyIcon />}
      onClick={async () => {
        setIsLoading(true);
        const result = await getStripeLoginLink();
        const url = result.data?.stripe_login_link;
        if (url) {
          window.open(url, "_blank");
        }
        setIsLoading(false);
      }}
    >
      Open Stripe Dashboard
    </LoadingButton>
  );
};
