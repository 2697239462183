import { initializeApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  uploadBytesResumable,
} from "firebase/storage";
import { ref } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { Environment } from "../environment";

// Dev Config

// Prod Config
const firebaseConfig = Environment.isDevelopment
  ? {
      apiKey: "AIzaSyD_Fn4O8ZqK4OQeIaCqlpo1vAQ2fEKM4UM",
      authDomain: "acro-world-development.firebaseapp.com",
      projectId: "acro-world-development",
      storageBucket: "acro-world-development.appspot.com",
      messagingSenderId: "1077598628073",
      appId: "1:1077598628073:web:aa35f4763ac8f2a569a878",
      measurementId: "G-CC01W3QM47",
    }
  : {
      apiKey: "AIzaSyBBqCy5y5Zli55kvG4nQ4dRHo58JLmsFFA",
      authDomain: "arcoworld-1415b.firebaseapp.com",
      projectId: "arcoworld-1415b",
      storageBucket: "arcoworld-1415b.appspot.com",
      messagingSenderId: "322527689838",
      appId: "1:322527689838:web:f223dc36b4ecba4cd8ba7f",
    };

export class Firebase {
  public static app = initializeApp(firebaseConfig);
  public static storage = getStorage(Firebase.app);

  public static uploadFiles = async (imageFiles: Blob[]): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      if (imageFiles.length === 0) {
        resolve([]);
      }
      const fileUrls: string[] = [];
      for (let i = 0; i < imageFiles?.length; i++) {
        const image = imageFiles[i];
        const storageRef = ref(
          Firebase.storage,
          `/teacher/images/uploads/${uuidv4()}`
        );

        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          () => {},
          (err: any) => reject(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              fileUrls.push(url);
              if (fileUrls.length === imageFiles.length) {
                resolve(fileUrls);
              }
            });
          }
        );
      }
    });
  };

  public static uploadFile = async (imageFile: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!imageFile) {
        resolve("");
      }
      const image = imageFile;
      const storageRef = ref(
        Firebase.storage,
        `/teacher/images/uploads/${uuidv4()}`
      );

      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        () => {},
        (err: any) => reject(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve(url);
          });
        }
      );
    });
  };
}
