import { makeVar, useReactiveVar } from "@apollo/client";
import { Alert, Box, Snackbar } from "@mui/material";

export const setGlobalErrorNotification = makeVar("");
export const setGlobalSuccessNotification = makeVar("");
export const setGlobalInfoNotification = makeVar("");

function GlobalNotificationOverlay() {
  const errorState = useReactiveVar(setGlobalErrorNotification);
  const successState = useReactiveVar(setGlobalSuccessNotification);
  const infoState = useReactiveVar(setGlobalInfoNotification);
  return (
    <Box>
      <Snackbar
        open={!!errorState}
        autoHideDuration={5000}
        onClose={() => {
          setGlobalErrorNotification("");
        }}
      >
        <Alert severity="error">{errorState}</Alert>
      </Snackbar>
      <Snackbar
        open={!!infoState}
        autoHideDuration={5000}
        onClose={() => {
          setGlobalInfoNotification("");
        }}
      >
        <Alert severity="info">{infoState}</Alert>
      </Snackbar>
      <Snackbar
        open={!!successState}
        autoHideDuration={5000}
        onClose={() => {
          setGlobalSuccessNotification("");
        }}
      >
        <Alert severity="success">{successState}</Alert>
      </Snackbar>
    </Box>
  );
}

export default GlobalNotificationOverlay;
