import { Box, Fab, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetTeachersPageableQuery,
  Teachers_Bool_ExpType,
  TeacherFieldsFragmentType,
} from "../../__generated___/gql";
import { ConfirmationStatus } from "../../models/confirmation-status.model";

const singleSelectFilterOperators = getGridSingleSelectOperators().filter(
  ({ value }) => ["is"].includes(value)
);

const stringFilterOperators = getGridStringOperators().filter(({ value }) =>
  ["contains"].includes(value)
);

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        placeholder={undefined}
      />
    </GridToolbarContainer>
  );
};

export const Teachers = () => {
  const navigate = useNavigate();
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);
  const [where, setWhere] = useState<Teachers_Bool_ExpType>({});
  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetTeachersPageableQuery({
    variables: {
      limit: limit,
      offset: offset,
      where: where,
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
      where: where,
    });
  }, [offset, where]);

  const columns: GridColDef<TeacherFieldsFragmentType>[] = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit">
            <Link to={`/app/teachers/${params.row.id}`} className="icon-link">
              <IconButton color="inherit" aria-label="open drawer" edge="start">
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 75,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={params.row?.images[0]?.image?.url}
          alt=""
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      filterOperators: stringFilterOperators,
    },
    {
      field: "confirmation_status",
      headerName: "Confirmation Status",
      filterOperators: singleSelectFilterOperators,
      valueOptions: Object.keys(ConfirmationStatus),
      filterable: true,
      width: 200,
      sortable: false,
    },

    {
      field: "location_name",
      headerName: "Location Name",
      width: 130,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box className="container">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Teachers</h1>
        <Fab
          onClick={() => {
            navigate(`/app/teachers/create`);
          }}
          variant="extended"
          color="primary"
          aria-label="add"
        >
          <AddIcon />
          Create Teacher
        </Fab>
      </Box>

      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isQueryLoading}
          rows={data ? data.teachers : []}
          rowCount={data ? data.teachers_aggregate?.aggregate?.count : 0}
          columns={columns}
          pageSize={pageSize}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          checkboxSelection={false}
          paginationMode="server"
          filterMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          onFilterModelChange={(filterModel) => {
            console.log("filterModel", filterModel);
            const conditionalOperatorMap: Record<string, string> = {
              and: "_and",
              or: "_or",
            };
            const operatorMap: Record<string, string> = {
              contains: "_like",
              is: "_eq",
            };
            const where: Teachers_Bool_ExpType = {
              ...(filterModel.linkOperator && {
                [conditionalOperatorMap[filterModel.linkOperator]]:
                  filterModel.items
                    .filter((item) => item.value)
                    .map((item) => ({
                      [item.columnField]: {
                        ...(item.operatorValue && {
                          [operatorMap[item.operatorValue]]: `${
                            item.value || ""
                          }`,
                        }),
                      },
                    })),
              }),
            };
            setWhere(where);
          }}
        />
      </div>
    </Box>
  );
};
