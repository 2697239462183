import "./class-events.scss";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import { DateTime } from "luxon";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { StorageKeys } from "../../storage/storage-keys.enum";
import { setGlobalSuccessNotification } from "../../components/global-notification-overlay/global-notification-overlay";
import { useNavigate, useParams } from "react-router-dom";
import { ViewList } from "@mui/icons-material";
import {
  ClassEventFieldsFragmentType,
  useCancelClassEventMutation,
  useGetClassEventsPageableQuery,
} from "../../__generated___/gql";

export const ClassEvents = () => {
  const pageSize = 50;
  const limit = pageSize;
  const navigate = useNavigate();
  const { classId } = useParams();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showPastEvents, setShowPastEvents] = useLocalStorage<boolean>(
    StorageKeys.ShowPastClassEvents,
    false
  );
  const [classEventToCancel, setClassEventToCancel] =
    useState<ClassEventFieldsFragmentType>();
  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetClassEventsPageableQuery({
    fetchPolicy: "network-only",
    variables: {
      classId,
      limit: limit,
      offset: offset,
      where: {
        class_id: { _eq: classId },
        ...(!showPastEvents && { start_date: { _gt: "now()" } }),
      },
    },
  });

  const [
    runCancelMutation,
    { loading: isCancelMutationLoading, data: cancelMutationData },
  ] = useCancelClassEventMutation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeCancelDialog = () => {
    setIsDialogOpen(false);
    setClassEventToCancel(undefined);
  };

  const columns: GridColDef<ClassEventFieldsFragmentType>[] = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        const classEvent = params.row;
        const isExpired =
          classEvent?.start_date && classEvent.start_date < DateTime.now();
        return (
          <Box display="flex" gap="10px">
            <Tooltip
              title={
                isExpired
                  ? "Class event already took place"
                  : classEvent.is_cancelled
                  ? "Class event was cancelled"
                  : "Cancel"
              }
            >
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  disabled={classEvent.is_cancelled || isExpired}
                  onClick={() => {
                    setIsDialogOpen(true);
                    setClassEventToCancel(classEvent);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Show bookings for this class event">
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => {
                    navigate(
                      `/app/classes/${classEvent?.class?.id}/events/${classEvent.id}/bookings`
                    );
                  }}
                >
                  <ViewList />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "week_day",
      headerName: "Weekday",
      width: 160,
      valueGetter: ({ row }) => {
        return DateTime.fromISO(row.start_date.toISO() ?? "").toFormat("EEEE");
      },
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.start_date.toLocaleString(DateTime.DATETIME_MED);
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.end_date.toLocaleString(DateTime.DATETIME_MED);
      },
    },
    {
      field: "is_cancelled",
      headerName: "Is cancelled",
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.is_cancelled ? "Yes" : "No";
      },
    },
  ];

  useEffect(() => {
    if (cancelMutationData) {
      setGlobalSuccessNotification("Cancelled!");
      refetch();
    }
  }, [cancelMutationData]);

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  useEffect(() => {
    setIsLoading(isQueryLoading || isCancelMutationLoading);
  }, [isQueryLoading, isCancelMutationLoading]);

  return (
    <Container>
      <Dialog open={isDialogOpen} onClose={closeCancelDialog}>
        <DialogTitle>Cancel class event?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to cancel occurence of ${classEventToCancel?.class?.name}, planned`}
            <p className="bold">
              {`
              ${classEventToCancel?.start_date.toLocaleString(
                DateTime.DATETIME_MED,
                { locale: "de" }
              )} - ${classEventToCancel?.end_date.toLocaleString(
                DateTime.TIME_24_SIMPLE,
                { locale: "de" }
              )}?
              `}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flex="1" justifyContent="space-between">
            <Button
              disabled={isCancelMutationLoading}
              onClick={closeCancelDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={isCancelMutationLoading}
              onClick={async () => {
                await runCancelMutation({
                  variables: {
                    id: classEventToCancel?.id,
                  },
                });
                closeCancelDialog();
              }}
              autoFocus
            >
              Yes, cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Upcoming "{data?.classes_by_pk?.name}" occurences</h1>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showPastEvents}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShowPastEvents(event.target.checked);
              }}
            />
          }
          label="Show past events"
        />
      </FormGroup>
      <div style={{ height: "65vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isLoading}
          rows={data?.class_events || []}
          rowCount={data ? data.class_events_aggregate.aggregate?.count : 0}
          columns={columns}
          pageSize={pageSize}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          checkboxSelection={false}
          paginationMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
        />
      </div>
    </Container>
  );
};
