import { Box, Button, Chip, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingOverlay } from "../../../components/loading-overlay/loading-overlay";
import { FileUtils } from "../../../lib/utils/file-utils";
import { Firebase } from "../../../lib/firebase";
import { useNavigate, useParams } from "react-router-dom";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../../../components/global-notification-overlay/global-notification-overlay";
import { useRoles } from "../../../hooks/use-roles";
import { RoleName } from "../../../enums/role.enum";
import { EventView } from "../../../components/events/event-view/event-view";
import {
  Events_Insert_InputType,
  Event_Source_EnumType,
  useUpsertEventAsAdminUserMutation,
  useUpsertEventAsTeacherUserMutation,
  useGetEventByPkAsAdminUserLazyQueryType,
  useGetEventByPkAsTeacherUserLazyQueryType,
  useInviteMutation,
} from "../../../__generated___/gql";
import { Event } from "../../../graphql/type-mapping/graphql-types.mapping";
import { Defaults } from "../../../defaults";
import { useMe } from "../../../components/protected-route/protected-route";
import { FormError } from "../../../models/form-error.model";
import { ScrollContainer } from "../../../components/scroll-container/scroll-container";

interface CreateEventFormProps {
  useLazyQuery:
    | typeof useGetEventByPkAsAdminUserLazyQueryType
    | typeof useGetEventByPkAsTeacherUserLazyQueryType;
  useMutation:
    | typeof useUpsertEventAsAdminUserMutation
    | typeof useUpsertEventAsTeacherUserMutation;
  onFinish?: () => void;
}

export const CreateEventForm = ({
  useLazyQuery,
  useMutation,
  onFinish,
}: CreateEventFormProps) => {
  const navigate = useNavigate();
  const roles = useRoles();
  const { me } = useMe();
  const isAdmin = roles.includes(RoleName.AdminUser);
  const [
    runUpsertEventMutation,
    { data, loading: isInsertMutationLoading, error: insertMutationError },
  ] = useMutation();
  const [
    inviteMutation,
    {
      data: inviteMutationData,
      error: inviteMutationError,
      loading: isInviteMutationLoading,
    },
  ] = useInviteMutation();
  const { id } = useParams();
  const isEdit = !!id;
  const [
    getEvent,
    {
      error: getEventError,
      loading: isGetEventLoading,
      data: getEventByPkData,
    },
  ] = useLazyQuery({
    fetchPolicy: "network-only",
  });

  const [value, setValue] = useState<Partial<Event>>({});
  const [imageSource, setImageSource] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (getEventByPkData?.events_by_pk) {
      console.log(
        "getEventByPkData?.events_by_pk",
        getEventByPkData?.events_by_pk
      );
      setValue({
        ...getEventByPkData?.events_by_pk,
        owners: getEventByPkData.events_by_pk.owners
          .filter((eventOwner) => eventOwner.user.id !== me.id)
          .map((eventOwner) => ({
            ...eventOwner?.user?.teacher_profile,
          })),
        teachers: getEventByPkData?.events_by_pk.teachers.map(
          (eventTeacher) => ({
            ...eventTeacher.teacher,
          })
        ),
      });
    }
  }, [getEventByPkData]);

  useEffect(() => {
    setIsLoading(
      isImageUploadLoading || isInsertMutationLoading || isGetEventLoading
    );
  }, [isImageUploadLoading, isInsertMutationLoading, isGetEventLoading]);

  useEffect(() => {
    console.log("insertMutationError", insertMutationError);
    setGlobalErrorNotification(
      insertMutationError?.message || getEventError?.message || ""
    );
  }, [insertMutationError, getEventError]);

  useEffect(() => {
    if (data) {
      setGlobalSuccessNotification("Success!");
      if (onFinish) {
        onFinish();
      } else {
        navigate("/app/events");
      }
    }
  }, [data]);

  return (
    <ScrollContainer>
      <LoadingOverlay isOpen={isLoading} />
      <div className="row">
        <h1>
          {isEdit && (
            <Box display="flex" alignItems="center" gap={1}>
              Update "{value.name}"{" "}
              {value.is_highlighted && (
                <Chip label="Highlighted" color="success" />
              )}
            </Box>
          )}
          {!isEdit && "Create Event"}
        </h1>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          {!isLoading && (
            <EventView
              event={value}
              isAdminView={roles.includes(RoleName.AdminUser)}
              onNewImageSet={(imageAsBase64: string) => {
                setImageSource(imageAsBase64);
              }}
              onEventUpdated={(newValue: Partial<Event>) => {
                setValue(newValue);
              }}
              addFormError={(key: string, message: string) => {
                setFormErrors([...formErrors, { key, message }]);
              }}
              removeFormError={(key: string) => {
                const index = formErrors.findIndex(
                  (error) => error.key === key
                );
                if (index > -1) {
                  formErrors.splice(index, 1);
                  setFormErrors([...formErrors]);
                }
              }}
            ></EventView>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {!isLoading && (
            <Button
              fullWidth
              variant="contained"
              onClick={async () => {
                if (!imageSource) {
                  setGlobalErrorNotification("Set a picture");
                  return;
                }
                if (!value?.name) {
                  setGlobalErrorNotification("Set a name");
                  return;
                }
                if (!value?.description) {
                  setGlobalErrorNotification("Set a description");
                  return;
                }
                if (!value?.location) {
                  setGlobalErrorNotification("Set a location");
                  return;
                }
                if (formErrors.length > 0) {
                  setGlobalErrorNotification(formErrors[0].message);
                  return;
                }
                try {
                  setIsImageUploadLoading(true);
                  let uploadedImageUrl;
                  if (imageSource && FileUtils.isBase64(imageSource)) {
                    const imageAsBlob = await FileUtils.convertBase64ToBlob(
                      imageSource
                    );
                    uploadedImageUrl = await Firebase.uploadFile(imageAsBlob);
                  }
                  console.log("value", value);
                  const event: Events_Insert_InputType = {
                    ...value,
                    ...(id && { id }),
                    main_image_url:
                      uploadedImageUrl ||
                      value.main_image_url ||
                      Defaults.EventImageUrl,
                    created_by: undefined,
                    event_source:
                      value.event_source || Event_Source_EnumType.SubmittedType,
                    invites: undefined,
                    owners: {
                      data: value.owners
                        ? value.owners
                            .filter((owner) => owner.user_id !== me.id)
                            .map((owner) => ({
                              user_id: owner?.user_id,
                            }))
                        : [],
                    },
                    teachers: {
                      data: value.teachers
                        ? value.teachers.map((teacher) => ({
                            teacher_id: teacher.id,
                          }))
                        : [],
                    },
                  };

                  if (!isAdmin) {
                    delete event.confirmation_status;
                  }

                  console.log("uploadedImageUrl", uploadedImageUrl);
                  console.log("value.main_image_url", value.main_image_url);

                  const upsertEventResult = await runUpsertEventMutation({
                    variables: {
                      event_id:
                        event.id || "00000000-0000-0000-0000-000000000000",
                      own_user_id: me.id,
                      event,
                    },
                  });

                  if (value?.invites?.length && value?.invites?.length > 0) {
                    // Class is created
                    const id = upsertEventResult.data?.insert_events_one?.id;
                    for (const invite of value.invites) {
                      if (invite.id) {
                        // If the invite is already created
                        continue;
                      }
                      await inviteMutation({
                        variables: {
                          email: invite.email,
                          entityId: id,
                          entityType: invite.entity,
                        },
                      });
                      setGlobalSuccessNotification(`Invited ${invite.email}`);
                    }
                  }
                } catch (e: any) {
                  setGlobalErrorNotification(e.message);
                } finally {
                  setIsImageUploadLoading(false);
                }
              }}
            >
              {isEdit ? "Update" : "Create"} Event
            </Button>
          )}
        </Grid>
      </Grid>
    </ScrollContainer>
  );
};
