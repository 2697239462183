import { useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import { InputProps } from "@mui/material";

interface FloatNumberInputProps {
  value?: number;

  error?: boolean;
  label?: string;
  hint?: string;
  InputProps?: Partial<InputProps>;

  onChange: (value: number) => void;
}

const FloatNumberInput = ({
  value,
  error,
  label,
  hint,
  InputProps,
  onChange,
}: FloatNumberInputProps) => {
  const [floatValue, setFloatValue] = useState(value?.toFixed(2));
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputRegex = /^-?\d*\.?\d{0,2}$/;
    const inputValue = event.target.value;

    if (inputRegex.test(inputValue)) {
      setFloatValue(inputValue);
      if (!inputValue.endsWith(".")) {
        onChange(Number(inputValue));
      }
    }
  };

  return (
    <TextField
      label={label}
      helperText={hint}
      error={error}
      variant="outlined"
      value={floatValue}
      onChange={handleInputChange}
      type="text"
      fullWidth
      InputProps={InputProps}
    />
  );
};

export default FloatNumberInput;
