import { Navigate } from "react-router-dom";
import { useUrlQuery } from "../../hooks/use-query";

const TokenCallback = () => {
  const urlQuery = useUrlQuery();
  const jwtTokenArg = urlQuery.get("jwtToken") || undefined;
  const refreshTokenArg = urlQuery.get("refreshToken") || undefined;
  const redirectTo = urlQuery.get("redirectTo") || undefined;

  if (jwtTokenArg) {
    window.localStorage.setItem("jwt-token", JSON.stringify(jwtTokenArg));
  }
  if (refreshTokenArg) {
    window.localStorage.setItem(
      "refresh-token",
      JSON.stringify(refreshTokenArg)
    );
  }

  return <Navigate to={redirectTo ? redirectTo : "/app"} />;
};

export default TokenCallback;
