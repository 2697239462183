import { Avatar, Box, Chip, Tooltip } from "@mui/material";
import { InviteModel } from "../../models/invite.model";
import InviteDialog from "../../dialogs/invite-dialog/invite-dialog";
import { useDeleteInviteByPkMutation } from "../../__generated___/gql";
import { useEffect } from "react";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../global-notification-overlay/global-notification-overlay";

interface InvitesProps {
  existingInvites: InviteModel[];
  buttonLabel: string;
  getTooltipTitle: (invite: InviteModel) => string;
  onInvitesUpdate: (invites: InviteModel[]) => void;
}
export const Invites = ({
  existingInvites,
  buttonLabel,

  getTooltipTitle,
  onInvitesUpdate,
}: InvitesProps) => {
  const [
    deleteInviteMutation,
    {
      data: deleteInviteMutationData,
      error: deleteInviteMutationError,
      loading: isDeleteInviteMutationLoading,
    },
  ] = useDeleteInviteByPkMutation();

  useEffect(() => {
    if (deleteInviteMutationData) {
      setGlobalSuccessNotification("Deleted invite");
      const index = existingInvites.findIndex(
        (invite) =>
          invite.id === deleteInviteMutationData?.delete_invites_by_pk?.id
      );
      existingInvites.splice(index, 1);
      onInvitesUpdate([...existingInvites]);
    }
  }, [deleteInviteMutationData]);

  useEffect(() => {
    if (deleteInviteMutationError) {
      setGlobalErrorNotification(deleteInviteMutationError?.message);
    }
  }, [deleteInviteMutationError]);

  return (
    <Box display="flex" flexDirection="row" gap="5px" flexWrap="wrap">
      {existingInvites.map((invite, index) => (
        <Tooltip key={`invite-${index}`} title={getTooltipTitle(invite)}>
          <Chip
            label={`${
              invite.email ?? invite.invited_user?.teacher_profile?.name
            } ${
              invite.confirmation_status
                ? `(${invite.confirmation_status})`
                : ""
            }`}
            color={invite.id ? "success" : "info"}
            avatar={
              <Avatar
                src={
                  invite.invited_user?.teacher_profile?.images?.find(
                    (image) => image.is_profile_picture
                  )?.image.url
                }
              ></Avatar>
            }
            onDelete={() => {
              console.log("onDelete", invite);
              if (invite.id) {
                deleteInviteMutation({ variables: { id: invite.id } });
              } else {
                existingInvites.splice(index, 1);
                onInvitesUpdate([...existingInvites]);
              }
            }}
          />
        </Tooltip>
      ))}

      <InviteDialog
        size="small"
        buttonLabel={buttonLabel}
        onConfirm={(newInvites) => {
          onInvitesUpdate([...existingInvites, ...newInvites]);
        }}
      />
    </Box>
  );
};
