import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

interface Currency {
  value: string;
  label: string;
  symbol: string;
}

export const CURRENCY_OPTIONS: Currency[] = [
  { value: "EUR", label: "Euro (EUR)", symbol: "€" },
  { value: "USD", label: "US Dollar (USD)", symbol: "$" },
  { value: "GBP", label: "British Pound (GBP)", symbol: "£" },
];

interface CurrencySelectorProps {
  value?: string;
  onSelect: (currency: Currency) => void;
}

const CurrencySelector = ({ value, onSelect }: CurrencySelectorProps) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="currency-selector-label">Currency</InputLabel>
      <Select
        label="Currency"
        value={value ?? CURRENCY_OPTIONS[0].value}
        onChange={(event) => {
          const newCurrency = CURRENCY_OPTIONS.find(
            (currencyOption) => currencyOption.value === event.target.value
          );
          if (newCurrency) {
            onSelect(newCurrency);
          }
        }}
        fullWidth
      >
        {CURRENCY_OPTIONS.map((currencyOption) => {
          return (
            <MenuItem key={currencyOption.value} value={currencyOption.value}>
              {currencyOption.symbol} {currencyOption.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CurrencySelector;
