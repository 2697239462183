import "./teacher-option.scss";
import { Avatar, Chip } from "@mui/material";
import { TeacherSlim } from "../../../models/teacher-slim.model";

interface TeacherOptionsProps {
  teacher: TeacherSlim | undefined;
  labelSuffix?: string;
  onDelete?: () => void;
}

export const TeacherOption = ({
  teacher,
  labelSuffix,
  onDelete,
}: TeacherOptionsProps) => {
  return (
    <Chip
      label={`${teacher?.name}${labelSuffix ?? ""}`}
      avatar={
        <Avatar
          src={
            teacher?.images?.find(
              (teacherImage) => teacherImage.is_profile_picture
            )?.image.url
          }
        ></Avatar>
      }
      onDelete={onDelete}
    />
  );
};
