import { Avatar, Box } from "@mui/material";
import { User } from "../../../models/user.type";

interface UserViewProps {
  user: Pick<User, "image_url" | "name">;
}

const UserView = ({ user }: UserViewProps) => {
  return (
    <Box className="avatar-container">
      <Avatar src={user.image_url || ""}>{user.name}</Avatar>
      <p>{user.name}</p>
    </Box>
  );
};
export default UserView;
