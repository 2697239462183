import { useJwt } from "react-jwt";
import { RoleName } from "../enums/role.enum";
import { AcroworldJwt } from "../models/acroworld-jwt.type";
import { useLocalStorage } from "./use-local-storage";

export const useRoles = (): RoleName[] => {
  const [token] = useLocalStorage("jwt-token", "");
  const { decodedToken } = useJwt<AcroworldJwt>(token);
  if (decodedToken) {
    return decodedToken["https://hasura.io/jwt/claims"][
      "x-hasura-allowed-roles"
    ];
  } else {
    return [];
  }
};
