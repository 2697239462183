import "./register.scss";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/use-local-storage";
import {
  Confirmation_Status_EnumType,
  useGetInviteByPkLazyQueryType,
  useRegisterAsTeacherMutation,
} from "../../__generated___/gql";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import logo from "../../assets/logo.png";
import { useUrlQuery } from "../../hooks/use-query";
import Invite from "../../components/invite/invite";
import {
  setGlobalErrorNotification,
  setGlobalInfoNotification,
} from "../../components/global-notification-overlay/global-notification-overlay";
import { ScrollContainer } from "../../components/scroll-container/scroll-container";

const Register = () => {
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();
  const inviteId = urlQuery.get("inviteId") || undefined;
  console.log("inviteId", inviteId);
  const [
    getInvite,
    { error: getInviteError, loading: isGetInviteLoading, data: getInviteData },
  ] = useGetInviteByPkLazyQueryType();
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [repeatPasswordInput, setRepeatPasswordInput] = useState("");
  const [repeatPasswordInputError, setRepeatPasswordInputError] =
    useState<string>("");
  const [registerAsTeacherMutation, { data, loading, error }] =
    useRegisterAsTeacherMutation({
      fetchPolicy: "network-only",
    });
  const [authState, setAuthState] = useLocalStorage("jwt-token", "");
  const setRefreshToken = useLocalStorage("refresh-token", "")[1];

  useEffect(() => {
    if (inviteId) {
      getInvite({
        variables: {
          id: inviteId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (getInviteData) {
      console.log(
        "Register:getInviteData",
        getInviteData,
        getInviteData.invite_by_id.email
      );
      setEmailInput(getInviteData.invite_by_id?.email || "");
    }
  }, [getInviteData]);

  useEffect(() => {
    if (getInviteError) {
      setGlobalErrorNotification(
        `Unable to load invite: ${getInviteError.message}.`
      );
    }
  }, [getInviteError]);

  if (
    getInviteData &&
    (getInviteData.invite_by_id.confirmation_status ===
      Confirmation_Status_EnumType.ConfirmedType ||
      getInviteData.invite_by_id.confirmation_status ===
        Confirmation_Status_EnumType.RejectedType)
  ) {
    setGlobalInfoNotification(
      `This invite is already ${getInviteData.invite_by_id.confirmation_status.toLowerCase()}. Navigating to register page.`
    );
    return <Navigate to={"/login"} />;
  }

  if (authState) {
    return <Navigate to="/app" />;
  }

  if (!loading && data && !error) {
    setAuthState(data.register_as_teacher.token);
    setRefreshToken(data.register_as_teacher.refreshToken);
    window.location.reload();
  }

  return (
    <ScrollContainer>
      <LoadingOverlay isOpen={loading || isGetInviteLoading} />
      <div className="App-header">
        <FormControl className="register-input-wrapper">
          <Box display="flex" justifyContent="center">
            <img src={logo} width={inviteId ? 100 : 200} alt="AcroWorld Logo" />
          </Box>

          {inviteId && (
            <Box>
              <Box display="flex" justifyContent="center">
                <Invite
                  loading={isGetInviteLoading}
                  invite={getInviteData?.invite_by_id}
                />
              </Box>
              {!getInviteError && (
                <Typography
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  Register or login to accept the invite
                </Typography>
              )}
            </Box>
          )}
          {error && <Alert severity="error">{error.message}</Alert>}
          <TextField
            id="register-name-input"
            label="Name"
            variant="outlined"
            onChange={(event) => setNameInput(event.target.value)}
          />
          <TextField
            id="register-email-input"
            label="Email"
            type="email"
            variant="outlined"
            value={emailInput}
            onChange={(event) => setEmailInput(event.target.value)}
          />

          <TextField
            id="register-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={(event) => setPasswordInput(event.target.value)}
          />

          <TextField
            id="register-repeat-password-input"
            label={"Repeat Password"}
            type="password"
            autoComplete="current-password"
            error={!!repeatPasswordInputError}
            helperText={repeatPasswordInputError}
            onChange={(event) => setRepeatPasswordInput(event.target.value)}
          />

          <Button
            variant="contained"
            onClick={() => {
              if (passwordInput !== repeatPasswordInput) {
                setRepeatPasswordInputError("Passwords do not match");
                return;
              }
              registerAsTeacherMutation({
                variables: {
                  email: emailInput,
                  name: nameInput,
                  password: passwordInput,
                  inviteId,
                },
              });
            }}
          >
            Register now
          </Button>
          <Divider>
            <Chip label="OR" />
          </Divider>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/login?${urlQuery.toString()}`);
            }}
          >
            Go To Login
          </Button>
        </FormControl>
      </div>
    </ScrollContainer>
  );
};
export default Register;
