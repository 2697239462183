import { gql } from "@apollo/client";
import Fragments from "../fragments";

export default class RoleQueries {
  public static GetPageable = gql`
  query GetRolesPageable($limit: Int, $offset: Int, $where: roles_bool_exp)  {
      roles(limit: $limit, offset: $offset, where: $where) {
        ${Fragments.Role}
      }
      roles_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }`;
}
