import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useMe } from "../../components/protected-route/protected-route";
import {
  useAcceptInviteMutation,
  useGetAllInvitesQuery,
  useRejectInviteMutation,
} from "../../__generated___/gql";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import Invite from "../../components/invite/invite";
import { setGlobalSuccessNotification } from "../../components/global-notification-overlay/global-notification-overlay";

export default function InvitesDialog() {
  const theme = useTheme();
  const { me } = useMe();
  const {
    data: getAllInvitesData,
    loading: isGetAllInvitesLoading,
    refetch: refetchAllInvites,
  } = useGetAllInvitesQuery({
    fetchPolicy: "network-only",
  });
  const [rejectInviteMutation] = useRejectInviteMutation();
  const [acceptInviteMutation] = useAcceptInviteMutation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitesLength, setInvitesLength] = useState(0);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  useEffect(() => {
    console.log("Dashboard:me", me);
    if (me && me.invites.length > 0) {
      handleTooltipOpen();
    }
  }, [me]);

  useEffect(() => {
    setIsLoading(isGetAllInvitesLoading);
  }, [isGetAllInvitesLoading]);

  useEffect(() => {
    if (getAllInvitesData) {
      const newInvitesLength = getAllInvitesData.get_all_invites.length;
      if (newInvitesLength <= 0) {
        handleClose();
      }
      setInvitesLength(newInvitesLength);
    }
  }, [getAllInvitesData]);

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  if (isLoading) {
    return <LoadingOverlay isOpen={true} />;
  }

  console.log("getAllInvitesData", getAllInvitesData);
  console.log("invitesLength", invitesLength);

  return (
    <div>
      <Box>
        <Tooltip
          open={isTooltipOpen}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          placement="left"
          title={
            invitesLength === 0
              ? "No pending invites"
              : invitesLength === 1
              ? "You have a pending invite"
              : "You have pending invites"
          }
        >
          <IconButton onClick={handleClickOpen} disabled={invitesLength === 0}>
            <Badge
              badgeContent={invitesLength >= 0 ? invitesLength : 0}
              color="secondary"
            >
              <MailOutlineIcon></MailOutlineIcon>
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Pending invites"}
        </DialogTitle>
        <DialogContent>
          {getAllInvitesData?.get_all_invites.map((invite) => (
            <Card style={{ marginBottom: "20px" }}>
              <CardMedia
                component="img"
                height="140"
                image={invite.class?.image_url || invite.event?.main_image_url}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Invite to "{invite?.class?.name || invite?.event?.name}"
                </Typography>
                <Invite invite={invite} />
              </CardContent>
              <Box display="flex" justifyContent="space-between" margin="5px">
                <Button
                  size="small"
                  color="primary"
                  onClick={async () => {
                    await rejectInviteMutation({
                      variables: {
                        id: invite.id,
                      },
                    });
                    refetchAllInvites();
                    setGlobalSuccessNotification("Rejected invite");
                  }}
                >
                  Reject
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={async () => {
                    await acceptInviteMutation({
                      variables: {
                        id: invite.id,
                      },
                    });
                    setGlobalSuccessNotification(
                      'Accepted invited. You can now see the event under "Events".'
                    );
                    refetchAllInvites();
                  }}
                >
                  Accept
                </Button>
              </Box>
            </Card>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
