import React from "react";
import PieChart from "./pie-chart";
import { PieChartData } from "../../models/pie-chart.type";

interface PieRowProps {
  data1: PieChartData;
  data2: PieChartData;
}

const PieRow: React.FC<PieRowProps> = ({ data1, data2 }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <PieChart data={data1} title="Participant Distribution" />
      <PieChart data={data2} title="Skill Level Distribution" />
    </div>
  );
};

export default PieRow;
