import { gql } from "@apollo/client";
import Fragments from "../fragments";

export default class UsersQueries {
  public static GetPageable = gql(`
  query GetUsersPageable($limit: Int, $offset: Int, $where: users_bool_exp!)  {
    users(limit: $limit, offset: $offset, where: $where) {
        ${Fragments.User}
      }
      users_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }`);
}
