import "./admin-classes-view.scss";
import { Box, Fab, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import { Class } from "../../models/class.type";
import { GetClassesResponse } from "../../graphql/models/responses/get-classes-response.type";
import Queries from "../../graphql/queries";

export const AdminClassesView = () => {
  const navigate = useNavigate();
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);
  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useQuery<GetClassesResponse>(Queries.GetClasses, {
    variables: {
      limit: limit,
      offset: offset,
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  const columns: GridColDef<Class>[] = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return (
          <Box display="flex" gap="10px">
            <Tooltip title="Edit">
              <Link to={`/app/classes/${params.row.id}`} className="icon-link">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                >
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="View occurences">
              <Link
                to={`/app/classes/${params.row.id}/events`}
                className="icon-link"
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                >
                  <EventIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 75,
      editable: true,
      renderCell: (params) => (
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={params.row?.image_url}
          alt=""
        />
      ),
    },
    { field: "name", headerName: "Name", width: 200, sortable: false },
    {
      field: "location_name",
      headerName: "Location Name",
      width: 130,
      sortable: false,
    },
  ];

  return (
    <Box className="container">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Classes</h1>
        <Fab
          onClick={() => {
            navigate(`/app/classes/create`);
          }}
          variant="extended"
          color="primary"
          aria-label="add"
        >
          <AddIcon />
          Create New
        </Fab>
      </Box>

      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isQueryLoading}
          rows={data ? data.classes : []}
          rowCount={data ? data.classes_aggregate.aggregate.count : 0}
          columns={columns}
          pageSize={pageSize}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          checkboxSelection={false}
          paginationMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
        />
      </div>
    </Box>
  );
};
