import { gql } from "@apollo/client";

export default class UserRolesMutations {
  public static Update = gql`
    mutation UpdateUserRoles(
      $userId: uuid!
      $userRoles: [user_roles_insert_input!]!
    ) {
      delete_user_roles(where: { user_id: { _eq: $userId } }) {
        affected_rows
      }
      insert_user_roles(objects: $userRoles) {
        affected_rows
      }
    }
  `;
}
