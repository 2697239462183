import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { Role } from "../../../models/role.model";
import RoleQueries from "../../../graphql/roles/role-queries";
import { GetRolesResponse } from "../../../graphql/models/responses/get-roles-response-model";

interface RoleSelectProps {
  roles: Role[];
  onChange: (roles: Role[]) => void;
}

const RoleSelect = ({ roles, onChange }: RoleSelectProps) => {
  const { loading, error, data } = useQuery<GetRolesResponse>(
    RoleQueries.GetPageable
  );
  if (loading) {
    return <CircularProgress color="inherit" />;
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  return (
    <Autocomplete
      options={data?.roles || []}
      autoHighlight
      multiple
      value={roles}
      disableClearable={true}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => option?.name}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option?.name}
            {...getTagProps({ index })}
            key={`teacher-tag-${index}`}
          />
        ))
      }
      renderOption={(props, option) => (
        <Box
          className="role-option"
          component="li"
          {...props}
          key={`role-option-${option?.id}`}
        >
          <p>{option?.name}</p>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose role/s"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};
export default RoleSelect;
