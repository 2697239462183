import { Box, CircularProgress, Typography } from "@mui/material";
import UserView from "../users/user-avatar/user-avatar";
import CancelIcon from "@mui/icons-material/Cancel";
import { InviteType } from "../../__generated___/gql";

interface InviteProps {
  loading?: boolean;
  invite?: InviteType;
}
const Invite = ({ invite, loading }: InviteProps) => {
  if (loading) {
    return <CircularProgress color="inherit" />;
  }
  if (!invite) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
        <CancelIcon color="error" />
        <Typography>Couldn't find invite</Typography>
      </Box>
    );
  }

  const createdBy = invite.created_by;
  const entity = invite.entity === "class" ? invite.class : invite.event;

  return (
    <Box display="flex" flexDirection="column">
      <UserView
        user={{
          name: createdBy.teacher_profile?.name || createdBy.name,
          image_url:
            createdBy.teacher_profile?.images?.[0]?.image?.url ||
            createdBy.image_url,
        }}
      ></UserView>
      {invite.entity && (
        <p>
          invited you to join AcroWorld and{" "}
          {invite.entity.endsWith("teacher")
            ? "be a teacher for"
            : "be an owner"}{" "}
          their event
          <span style={{ fontWeight: "bold" }}>{entity?.name}</span>
        </p>
      )}
      {!invite.entity && <p>invited you to join AcroWorld !</p>}
    </Box>
  );
};
export default Invite;
