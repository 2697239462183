import "./loading-overlay.scss";
import { Backdrop, Box, CircularProgress } from "@mui/material";

export const LoadingOverlay = ({
  isOpen = true,
  text,
}: {
  isOpen?: boolean;
  text?: string;
}) => {
  return (
    <Backdrop open={isOpen} style={{ zIndex: 9999 }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />
        {text && <p className="overlay-text">{text}</p>}
      </Box>
    </Backdrop>
  );
};
