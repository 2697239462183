import "./users.scss";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RoleSelect from "../roles/role-select/role-select";
import { useMutation } from "@apollo/client";
import UserRolesMutations from "../../graphql/user-roles/user-role-mutations";
import { setGlobalSuccessNotification } from "../global-notification-overlay/global-notification-overlay";
import { DateTime } from "luxon";
import {
  useGetUsersPageableQuery,
  UserFieldsFragmentType,
  Users_Bool_ExpType,
} from "../../__generated___/gql";
import { RoleName } from "../../enums/role.enum";

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ["contains"].includes(value)
);

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        placeholder={undefined}
      />
    </GridToolbarContainer>
  );
};

export const UsersView = () => {
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);
  const [where, setWhere] = useState<Users_Bool_ExpType>({});
  const [isUpdateRolesDialogOpen, setIsUpdateRolesDialogOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState<UserFieldsFragmentType>();
  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetUsersPageableQuery({
    variables: {
      limit,
      offset,
      where: {},
    },
  });

  const [runUpdateUserRolesMutation, { data: userRolesUpdateData }] =
    useMutation(UserRolesMutations.Update);

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
      where: where,
    });
  }, [offset, where]);

  useEffect(() => {
    if (userRolesUpdateData) {
      setGlobalSuccessNotification("Updated roles");
    }
  }, [userRolesUpdateData]);

  const columns: GridColDef<UserFieldsFragmentType>[] = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        const user = params.row;
        return (
          <Tooltip title="User Roles">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                setUserToUpdate(user);
                setIsUpdateRolesDialogOpen(true);
              }}
            >
              <ManageAccountsIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },

    {
      field: "image",
      headerName: "Image",
      width: 75,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={params.row?.image_url || ""}
          alt=""
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      filterable: true,
      filterOperators: filterOperators,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.name}>
            <p>{params.row.name}</p>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: false,
      filterable: true,
      filterOperators: filterOperators,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.email}>
            <p>{params.row.email}</p>
          </Tooltip>
        );
      },
    },
    {
      field: "origin_id",
      headerName: "Origin Id",
      width: 200,
      sortable: false,
      filterable: true,
      filterOperators: filterOperators,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.origin_id}>
            <p>{params.row.origin_id}</p>
          </Tooltip>
        );
      },
    },
    {
      field: "roles",
      headerName: "Roles",
      width: 150,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const roleString = params.row.user_roles
          .map((user_role) => user_role.role.name)
          .join(", ");
        return (
          <Tooltip title={roleString}>
            <p>
              {params.row.user_roles
                .map((user_role) => user_role.role.name)
                .join(", ")}
            </p>
          </Tooltip>
        );
      },
    },

    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const dateString = params.row.created_at.toLocaleString(
          DateTime.DATETIME_MED
        );
        return (
          <Tooltip title={dateString}>
            <p>{dateString}</p>
          </Tooltip>
        );
      },
    },
  ];

  const closeUpdateRolesDialog = (reloadTable = false) => {
    setIsUpdateRolesDialogOpen(false);
    setUserToUpdate(undefined);
    if (reloadTable) {
      refetch({
        limit,
        offset,
        where,
      });
    }
  };

  return (
    <Box className="container">
      <Dialog
        open={isUpdateRolesDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Updating roles of {userToUpdate?.name}</DialogTitle>
        <DialogContent>
          <Box className="dialog-content">
            <RoleSelect
              roles={
                userToUpdate?.user_roles
                  .filter((userRole) => !!userRole)
                  .map(
                    (userRole) =>
                      userRole.role as { id: string; name: RoleName }
                  ) || []
              }
              onChange={(roles) => {
                console.log("newRoles", roles);
                if (userToUpdate) {
                  setUserToUpdate({
                    ...userToUpdate,
                    user_roles: roles.map((role) => ({ role })),
                  });
                }
              }}
            ></RoleSelect>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flex="1" justifyContent="space-between">
            <Button onClick={() => setIsUpdateRolesDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await runUpdateUserRolesMutation({
                  variables: {
                    userId: userToUpdate?.id,
                    userRoles: userToUpdate?.user_roles.map((userRole) => ({
                      user_id: userToUpdate.id,
                      role_id: userRole.role.id,
                    })),
                  },
                });
                closeUpdateRolesDialog(true);
              }}
              variant="contained"
              autoFocus
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Users</h1>
      </Box>

      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isQueryLoading}
          rows={data ? data.users : []}
          rowCount={data ? data?.users_aggregate?.aggregate?.count : 0}
          columns={columns}
          pageSize={pageSize}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          checkboxSelection={false}
          paginationMode="server"
          filterMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          onFilterModelChange={(filterModel) => {
            const conditionalOperatorMap: Record<string, string> = {
              and: "_and",
              or: "_or",
            };
            const operatorMap: Record<string, string> = {
              contains: "_ilike",
            };
            const where: Users_Bool_ExpType = {
              ...(filterModel.linkOperator && {
                [conditionalOperatorMap[filterModel.linkOperator]]:
                  filterModel.items.map((item) => ({
                    [item.columnField]: {
                      ...(item.operatorValue && {
                        [operatorMap[item.operatorValue]]: `%${
                          item.value || ""
                        }%`,
                      }),
                    },
                  })),
              }),
            };
            setWhere(where);
          }}
        />
      </div>
    </Box>
  );
};
