import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { RecurringPatternModel } from "../../../../models/recurring-pattern.model";
import { useState } from "react";
import RecurringPatternInput from "../recurring-pattern-input/recurring-pattern-input";

interface EditRecurringPatternDialogProps {
  pattern: RecurringPatternModel;
  onSave: (pattern: RecurringPatternModel) => void;
  onCancel: () => void;
}

export const EditRecurringPatternDialog = ({
  pattern,
  onSave,
  onCancel,
}: EditRecurringPatternDialogProps) => {
  const [patternInput, setPatternInput] = useState(pattern);
  return (
    <Dialog open={true}>
      <DialogTitle>
        {pattern.id ? "Update occurence pattern" : "Create occurence pattern"}
      </DialogTitle>
      <DialogContent>
        <RecurringPatternInput
          pattern={patternInput}
          onChange={(recurringPattern: RecurringPatternModel) => {
            setPatternInput(recurringPattern);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSave(patternInput);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
