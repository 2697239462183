import "./teacher-select.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Queries from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { GetTeachersResponse } from "../../../graphql/models/responses/get-teachers-response.type";
import { Alert, CircularProgress } from "@mui/material";
import { TeacherSlim } from "../../../models/teacher-slim.model";

interface TeacherSelectProps {
  teachers: TeacherSlim[];
  disabled?: boolean;
  label?: string;
  onChange: (teachers: TeacherSlim[]) => void;
}

const TeacherSelect = ({
  teachers,
  disabled,
  label,
  onChange,
}: TeacherSelectProps) => {
  const { loading, error, data } = useQuery<GetTeachersResponse>(
    Queries.GetTeachers
  );
  if (loading) {
    return <CircularProgress color="inherit" />;
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  return (
    <Autocomplete
      options={data?.teachers || []}
      disabled={disabled}
      autoHighlight
      multiple
      disableCloseOnSelect
      value={teachers}
      disableClearable={true}
      onChange={(_, newValue) => {
        if (newValue) {
          const fixedTeacher = teachers.find((teacher) => teacher.isFixed);
          if (
            fixedTeacher &&
            !newValue.find((teacher) => teacher.id === fixedTeacher.id)
          ) {
            newValue.push(fixedTeacher);
          }
          onChange(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => option?.name || ""}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, _) => (
          <Box
            className="teacher-option-wrapper"
            key={`teacher-option-${option?.id}`}
          >
            <img
              className="teacher-image"
              loading="lazy"
              src={option?.images?.[0]?.image?.url}
              alt=""
            />
            <Box className="teacher-info" component="li">
              <p>{option?.name}</p>
              <p className="location-name">{option?.location_name}</p>
            </Box>
          </Box>
        ))
      }
      renderOption={(props, option) => (
        <Box
          className="teacher-option-wrapper"
          component="li"
          {...props}
          key={`teacher-option-${option?.id}`}
        >
          <img
            className="teacher-image"
            loading="lazy"
            src={option?.images?.[0]?.image?.url}
            alt=""
          />
          <Box className="teacher-info" component="li">
            <p>{option?.name}</p>
            <p className="location-name">{option?.location_name}</p>
          </Box>
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            className="teacher-select"
            label={label || "Add teacher/s"}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        );
      }}
    />
  );
};
export default TeacherSelect;
