import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  CreatedInvitesFieldsFragmentType,
  Created_Invites_Bool_ExpType,
  useGetCreatedInvitesPageableQuery,
  useInviteMutation,
} from "../../__generated___/gql";
import InviteDialog from "../../dialogs/invite-dialog/invite-dialog";
import { setIsGlobaLoadingVisible } from "../../components/global-loading-overlay/global-loading-overlay";
import { DateTime } from "luxon";
import { setGlobalErrorNotification } from "../../components/global-notification-overlay/global-notification-overlay";

export const InviteList = () => {
  const pageSize = 50;
  const limit = pageSize;
  const [where, setWhere] = useState<Created_Invites_Bool_ExpType>({});
  const [offset, setOffset] = useState(0);
  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetCreatedInvitesPageableQuery({
    variables: { limit, offset, where },
  });

  const [
    inviteMutation,
    {
      data: inviteMutationData,
      error: inviteMutationError,
      loading: isInviteMutationLoading,
    },
  ] = useInviteMutation();

  useEffect(() => {
    setIsGlobaLoadingVisible({ isOpen: false });
    refetch();
  }, [inviteMutationData]);

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  useEffect(() => {
    if (inviteMutationError) {
      setGlobalErrorNotification(inviteMutationError?.message);
    }
  });

  const columns: GridColDef<CreatedInvitesFieldsFragmentType>[] = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
      renderCell: (params) => params.row.invited_user?.name,
    },
    { field: "email", headerName: "Email", width: 200, sortable: false },
    {
      field: "confirmation_status",
      headerName: "Confirmation Status",
      width: 200,
      sortable: false,
    },
    { field: "entity", headerName: "Entity", width: 130, sortable: false },
    {
      field: "entityName",
      headerName: "Entity Name",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.entity === "class"
          ? params.row?.class?.name
          : params.row?.event?.name,
    },
    {
      field: "start_date",
      headerName: "Invited at",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        return row.created_at.toLocaleString(DateTime.DATETIME_MED);
      },
    },
  ];

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Invites</h1>
        <InviteDialog
          onlyByEmail={true}
          onConfirm={(invites) => {
            setIsGlobaLoadingVisible({ isOpen: true });
            for (const invite of invites) {
              console.log("inviteMutation", invite);
              inviteMutation({
                variables: {
                  ...(invite?.email && { email: invite.email }),
                  ...(invite.invited_user?.id && {
                    userId: invite.invited_user?.id,
                  }),
                },
              });
            }
          }}
        />
      </Box>

      <p>
        Here you can easily send invitations to fellow AcroYoga teachers,
        inviting them to join the AcroWorld familiy and expand our community.{" "}
      </p>

      <div style={{ height: "65vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isQueryLoading}
          rows={data ? data.created_invites : []}
          rowCount={data ? data.created_invites_aggregate.aggregate?.count : 0}
          columns={columns}
          pageSize={pageSize}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          checkboxSelection={false}
          paginationMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
        />
      </div>
    </Container>
  );
};
