import "./teacher.scss";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Teacher } from "../../../models/teacher.type";
import UserSelect from "../../users/user-select/user-select";
import { User } from "../../../models/user.type";
import RoleAware from "../../role-aware";
import { RoleName } from "../../../enums/role.enum";
import { setIsGlobaLoadingVisible } from "../../global-loading-overlay/global-loading-overlay";
import ImageInput from "../../image-input/image-input";
import { useReactiveVar } from "@apollo/client";
import { setMe } from "../../../reactive-vars/me";
import {
  Confirmation_Status_EnumType,
  Teacher_Type_EnumType,
  useCreateStripeUserMutation,
  useIsTeacherSlugAvailableLazyQueryType,
  useSendVerificationEmailMutation,
} from "../../../__generated___/gql";
import { useGetTeacherSlugSuggestionLazyQueryType } from "../../../__generated___/gql";
import UrlSlugInput from "../../url-slug-input/url-slug-input";
import LoadingButton from "@mui/lab/LoadingButton";
import CableIcon from "@mui/icons-material/Cable";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { OpenStripeDashboardButton } from "../../open-stripe-dashboard-button/open-stripe-dashboard-button";
import { TeacherFormField } from "../enums/teacher-form-field";

interface TeacherViewProps {
  value: Partial<Teacher> | undefined;
  isAdminView?: boolean;
  isEditView?: boolean;
  isIntroductionForm?: boolean;
  onNewProfileImageSet: (profileImageAsBase64: string | undefined) => void;
  onNewImagesSet: (imagesAsBase64: string[]) => void;
  onTeacherUpdated: (clazz: Partial<Teacher>) => void;
  addFormError: (key: string, message: string) => void;
  removeFormError: (key: string) => void;
}

export const TeacherView = ({
  value,
  isAdminView,
  isEditView,
  isIntroductionForm,
  onNewProfileImageSet,
  onNewImagesSet,
  onTeacherUpdated,
  addFormError,
  removeFormError,
}: TeacherViewProps) => {
  const me = useReactiveVar(setMe);
  const [signupMessageInput, setSignupMessageInput] = useState(
    value?.signup_message
  );
  const [profileImageSource, setProfileImageSource] = useState(
    value?.images?.find((image) => image.is_profile_picture)?.image.url
  );
  const [isUrlSlugAvailableQuery] = useIsTeacherSlugAvailableLazyQueryType({
    fetchPolicy: "network-only",
  });
  const [getSlugSuggestionQuery] = useGetTeacherSlugSuggestionLazyQueryType({
    fetchPolicy: "network-only",
  });
  const [nameInput, setNameInput] = useState(value?.name || "");
  const [instagramUrlInput, setInstagramUrlInput] = useState(
    value?.instagram_name || ""
  );
  const [descriptionInput, setDescriptionInput] = useState(
    value?.description || ""
  );
  const [imageSources, setImageSources] = useState<string[]>(
    value?.images
      ?.filter((image) => !image.is_profile_picture)
      .map((image) => image.image.url) || []
  );
  const [isOrganization, setIsOrganization] = useState<boolean>(
    value?.is_organization || false
  );

  const [typeInput, setType] = useState<Teacher_Type_EnumType>(
    value?.type || Teacher_Type_EnumType.TeacherType
  );
  const [user, setUser] = useState<User | undefined>(value?.user);
  const [confirmationStatus, setConfirmationStatus] = useState<
    Confirmation_Status_EnumType | undefined
  >(value?.confirmation_status);
  const [urlSlugInput, setUrlSlugInput] = useState(value?.url_slug);
  const [
    sendVerificationEmailMutation,
    {
      loading: isSendVerificationEmailMutationDataLoading,
      data: sendVerificationEmailMutationData,
    },
  ] = useSendVerificationEmailMutation();
  const [
    createStripeUserMutation,
    { loading: isCreateStripeUserMutationLoading },
  ] = useCreateStripeUserMutation();

  const callGetSlugSuggestion = async () => {
    if (urlSlugInput) {
      return;
    }
    setIsGlobaLoadingVisible({ isOpen: true, text: "Loading suggestion" });
    const { data } = await getSlugSuggestionQuery({
      variables: { name: nameInput as string },
    });
    if (data?.get_teacher_slug_suggestion) {
      setUrlSlugInput(data.get_teacher_slug_suggestion);
    }
    setIsGlobaLoadingVisible({ isOpen: false });
  };

  useEffect(() => {
    onTeacherUpdated({
      id: value?.id,
      name: nameInput,
      description: descriptionInput,
      user: user,
      instagram_name: instagramUrlInput,
      is_organization: isOrganization,
      signup_message: signupMessageInput,
      confirmation_status: confirmationStatus,
      type: typeInput,
      url_slug: urlSlugInput,
      stripe_id: value?.stripe_id,
      is_stripe_enabled: value?.is_stripe_enabled,
    });
  }, [
    user,
    nameInput,
    descriptionInput,
    instagramUrlInput,
    isOrganization,
    signupMessageInput,
    confirmationStatus,
    typeInput,
    urlSlugInput,
  ]);

  useEffect(() => {
    if (value?.name) {
      callGetSlugSuggestion();
    }
  }, []);

  useEffect(() => {
    if (value?.name) {
      setNameInput(value.name);
      callGetSlugSuggestion();
    }
  }, [value?.name]);

  useEffect(() => {
    onNewProfileImageSet(profileImageSource);
  }, [profileImageSource]);

  useEffect(() => {
    onNewImagesSet(imageSources);
  }, [imageSources]);

  const commonStyles = {
    bgcolor: "background.paper",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    width: "15rem",
    height: "15rem",
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {isAdminView && value?.signup_message && (
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <h3>Application information</h3>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Application"
                type="text"
                variant="outlined"
                value={value?.signup_message}
                disabled
                fullWidth
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
          </Grid>
        )}
        {!isIntroductionForm && (
          <RoleAware visibleFor={[RoleName.AdminUser]}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <UserSelect
                  value={user}
                  disabled={isEditView}
                  onChange={(user: User | undefined) => {
                    setUser(user);
                  }}
                  noOptionsText="No eligible users found. Eligible users must have the TeacherUser role and no existing teacher profile."
                  where={{
                    _not: {
                      teacher_profile: {},
                    },
                    user_roles: {
                      role: {
                        name: {
                          _eq: RoleName.TeacherUser,
                        },
                      },
                    },
                  }}
                ></UserSelect>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="test-select-label">
                    Confirmation Status
                  </InputLabel>
                  <Select
                    labelId="test-select-label"
                    label="Confirmation Status"
                    value={confirmationStatus}
                    onChange={(event) => {
                      setConfirmationStatus(
                        event.target.value as Confirmation_Status_EnumType
                      );
                    }}
                    fullWidth
                  >
                    <MenuItem
                      value={Confirmation_Status_EnumType.ConfirmedType}
                    >
                      {Confirmation_Status_EnumType.ConfirmedType}
                    </MenuItem>
                    <MenuItem value={Confirmation_Status_EnumType.PendingType}>
                      {Confirmation_Status_EnumType.PendingType}
                    </MenuItem>
                    <MenuItem value={Confirmation_Status_EnumType.RejectedType}>
                      {Confirmation_Status_EnumType.RejectedType}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </RoleAware>
        )}
        <Grid item xs={12} sm={4} position="relative">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="profile-image-wrapper"
          >
            <Box sx={{ ...commonStyles, borderRadius: "50%" }}>
              <ImageInput
                previewRound={true}
                sources={profileImageSource ? [profileImageSource] : []}
                onLoadStart={() => {}}
                onImagesLoaded={(sources: string[]) => {
                  setProfileImageSource(sources[0]);
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} position="relative">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} position="relative">
              <TextField
                label={"Name"}
                type="text"
                variant="outlined"
                value={nameInput}
                required
                fullWidth
                onBlur={() => {
                  callGetSlugSuggestion();
                }}
                onChange={(event) => {
                  setNameInput(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} position="relative">
              <UrlSlugInput
                required={true}
                urlSlug={urlSlugInput}
                onUrlSlugUpdate={(urlSlug: string) => {
                  setUrlSlugInput(urlSlug);
                }}
                hint={`In the future we will use the url slug in the url to your teacher profile. (e.g. acroworld.de/teachers/${
                  urlSlugInput ? urlSlugInput : "<your-url-slug>"
                })`}
                isUrlSlugAvailable={async (urlSlug: string) => {
                  const queryResult = await isUrlSlugAvailableQuery({
                    variables: {
                      urlSlug,
                    },
                  });
                  const isAvailable =
                    (urlSlug !== "" &&
                      queryResult.data?.is_teacher_slug_available) ??
                    false;
                  if (!isAvailable) {
                    addFormError(
                      TeacherFormField.UrlSlug,
                      "Url slug is not available"
                    );
                  } else {
                    removeFormError(TeacherFormField.UrlSlug);
                  }
                  return isAvailable;
                }}
              ></UrlSlugInput>
            </Grid>
            <Grid item xs={12} sm={12} position="relative">
              <TextField
                label="Description"
                type="text"
                variant="outlined"
                required
                multiline
                fullWidth
                minRows={5}
                maxRows={5}
                value={descriptionInput}
                onChange={(event) => setDescriptionInput(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isIntroductionForm && (
          <RoleAware visibleFor={[RoleName.TeacherUser]}>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <h3>Get paid via AcroWorld</h3>
                <p>
                  AcroWorld uses Stripe as our trusted payment provider to
                  ensure secure and seamless transactions for AcroYoga teachers.
                  With Stripe, teachers can easily receive payments, providing a
                  hassle-free experience for both instructors and students.
                </p>
              </Grid>
              {me?.is_email_verified && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent="center"
                >
                  {me?.teacher_profile?.is_stripe_enabled && (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <Alert severity="success">
                          Stripe is connected and payouts enabled
                        </Alert>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <OpenStripeDashboardButton />
                      </Grid>
                    </Grid>
                  )}

                  {!me?.teacher_profile?.is_stripe_enabled &&
                    !me?.teacher_profile?.stripe_id && (
                      <LoadingButton
                        variant="outlined"
                        loading={isCreateStripeUserMutationLoading}
                        loadingPosition="start"
                        startIcon={<CableIcon />}
                        onClick={async () => {
                          const result = await createStripeUserMutation();
                          const url = result.data?.create_stripe_user?.url;
                          if (url) {
                            window.location.replace(url);
                          }
                        }}
                      >
                        Connect Stripe
                      </LoadingButton>
                    )}

                  {!me?.teacher_profile?.is_stripe_enabled &&
                    me?.teacher_profile?.stripe_id && (
                      <Alert severity="info">
                        Stripe is connected but payouts are not enabled yet.
                        Maybe you have to verify your email submitted to Stripe.
                        Click{" "}
                        <a
                          href={`/app/stripe-callback?stripeId=${me?.teacher_profile?.stripe_id}&reCheck=true`}
                        >
                          here when you are finished
                        </a>
                        .
                      </Alert>
                    )}
                </Grid>
              )}
              {!me?.is_email_verified && (
                <Grid item xs={12} sm={12}>
                  <p>
                    To receive payments we need you to verify your email. Here
                    you can request to resend a verification email to you.
                  </p>
                  <LoadingButton
                    loading={isSendVerificationEmailMutationDataLoading}
                    loadingPosition="start"
                    startIcon={
                      sendVerificationEmailMutationData?.send_verification_email
                        .success ? (
                        <MarkEmailReadIcon />
                      ) : (
                        <MailIcon />
                      )
                    }
                    onClick={async () => {
                      if (
                        !sendVerificationEmailMutationData
                          ?.send_verification_email.success
                      ) {
                        await sendVerificationEmailMutation();
                      }
                    }}
                  >
                    {sendVerificationEmailMutationData?.send_verification_email
                      .success
                      ? "Sent"
                      : "Resend email"}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </RoleAware>
        )}
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <h3>How do you want to be displayed in the app? </h3>
          </Grid>
          <p>
            You can change this setting at any time.
            {typeInput === Teacher_Type_EnumType.TeacherType &&
              " With this setting your account will be displayed as Teacher."}
            {typeInput === Teacher_Type_EnumType.StudioType &&
              " With this setting your account will be displayed as Studio (e.g. a location for AcroYoga classes employing one more teacher/s)."}
            {typeInput === Teacher_Type_EnumType.AnonymousType &&
              " With this setting your account will be not be displayed in the app."}
          </p>
          <ToggleButtonGroup
            fullWidth
            exclusive
            value={typeInput}
            onChange={(_, newValue) => {
              if (newValue !== null) {
                setType(newValue);
              }
            }}
          >
            <ToggleButton
              style={{ height: 56 }}
              value={Teacher_Type_EnumType.TeacherType}
            >
              <Tooltip title="You are displayed as a teacher">
                <p>Teacher</p>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              style={{ height: 56 }}
              value={Teacher_Type_EnumType.StudioType}
            >
              <Tooltip title="You are displayed as a studio">
                <p>Studio</p>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              style={{ height: 56 }}
              value={Teacher_Type_EnumType.AnonymousType}
            >
              <Tooltip title="You are not displayed at at all in the app">
                <p>Anonymous</p>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} sm={12} position="relative">
          <h3>Additional images</h3>
          <ImageInput
            sources={imageSources}
            multiple={true}
            onImagesLoaded={(sources: string[]) => {
              setIsGlobaLoadingVisible({ isOpen: false });
              setImageSources(sources);
            }}
          />
        </Grid>

        {isIntroductionForm && me?.used_teacher_signup && (
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <h3>Additonal information</h3>
            </Grid>
            <Grid item xs={12} sm={12}>
              <p>
                How did you hear about us? Or anything else you want to add?
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Write something..."
                type="text"
                variant="outlined"
                value={signupMessageInput}
                fullWidth
                multiline
                minRows={5}
                maxRows={5}
                onChange={(event) => {
                  setSignupMessageInput(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
