import "./scroll-container.scss";
import { Box, Container } from "@mui/material";
import { ReactNode } from "react";

interface ScrollContainerProps {
  children: ReactNode;
  onScrollBoundReached?: () => void;
}

export const ScrollContainer = ({
  children,
  onScrollBoundReached,
}: ScrollContainerProps) => {
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const container = event.currentTarget;

    const isNearBottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10;

    if (isNearBottom && onScrollBoundReached) {
      onScrollBoundReached();
    }
  };

  return (
    <Box
      className="scroll-container"
      onScroll={(e) => handleScroll(e)}
      onTouchMove={(e) => handleScroll(e)}
    >
      <Container>{children}</Container>
    </Box>
  );
};
