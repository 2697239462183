import React from "react";
import styles from "./booking-overview-row.module.scss";
import { Divider, Grid, Typography } from "@mui/material";

interface Item {
  title: string;
  subtitle: string;
}

interface BookingOverviewRowProps {
  items: Item[];
}

const StretchedRowItem: React.FC<Item> = ({ title, subtitle }) => (
  <Grid item xs className={styles.item}>
    <Typography variant="h6">{title}</Typography>
    <Typography variant="h4">{subtitle}</Typography>
  </Grid>
);

const BookingOverviewRow: React.FC<BookingOverviewRowProps> = ({ items }) => (
  <Grid container className={styles.stretchedRow}>
    {items.map((item, index) => (
      <React.Fragment key={index}>
        <StretchedRowItem title={item.title} subtitle={item.subtitle} />
        {index < items.length - 1 && (
          <Divider orientation="vertical" flexItem className={styles.divider} />
        )}
      </React.Fragment>
    ))}
  </Grid>
);

export default BookingOverviewRow;
