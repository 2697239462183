import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DateTime } from "luxon";

import { useParams } from "react-router-dom";
import { useMe } from "../protected-route/protected-route";
import {
  ClassEventBookingsFieldsFragmentType,
  useGetClassEventBookingsByClassEventPageableQuery,
} from "../../__generated___/gql";
import BookingOverviewRow from "../bookings/booking-overview-row/booking-overview-row";
import PieRow from "../pie-chart/pie-chart-row";
import { PieChartData } from "../../models/pie-chart.type";

export const SingleClassEvent = () => {
  const pageSize = 50;
  const limit = pageSize;
  const { classEventId } = useParams();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { me } = useMe();

  console.log("me", me);

  const teacherId = me?.teacher_profile?.id;
  const { loading, error, data, refetch } =
    useGetClassEventBookingsByClassEventPageableQuery({
      variables: { limit, offset, teacherId, classEventId },
    });

  // based on the date, calculate the amount of successful bookings
  const successfulBookings = data?.class_event_bookings.filter((booking) => {
    return booking.status === "Success";
  });

  // based on the date, calculate the total revenue
  const totalRevenue = data?.class_event_bookings.reduce((acc, booking) => {
    return acc + booking.booking_option?.price;
  }, 0);

  console.log("data", data);
  // Unpack the booking data from the data object
  const bookings: ClassEventBookingsFieldsFragmentType[] | undefined =
    data?.class_event_bookings;

  // from the bookings, define the pieData1 as the distribution of the user levels
  const pieData1: PieChartData = {
    labels: ["Beginner", "Intermediate", "Advanced", "Not defined"],
    datasets: [
      {
        data: [
          bookings?.filter((booking) => {
            return booking.user?.level?.name === "Beginner";
          }).length ?? 0,
          bookings?.filter((booking) => {
            return booking.user?.level?.name === "Intermediate";
          }).length ?? 0,
          bookings?.filter((booking) => {
            return booking.user?.level?.name === "Advanced";
          }).length ?? 0,
          bookings?.filter((booking) => {
            return booking.user?.level?.name === null;
          }).length ?? 0,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#000000"],
      },
    ],
  };

  // from the bookings, define the pieData2 as the distribution of the user acro role
  const pieData2: PieChartData = {
    labels: ["Base", "Flyer", "Flyer and Base", "Not defined"],
    datasets: [
      {
        data: [
          bookings?.filter((booking) => {
            return booking.user?.acro_role?.name === "Base";
          }).length ?? 0,
          bookings?.filter((booking) => {
            return booking.user?.acro_role?.name === "Flyer";
          }).length ?? 0,
          bookings?.filter((booking) => {
            return booking.user?.acro_role?.name === "Flyer and Base";
          }).length ?? 0,
          bookings?.filter((booking) => {
            return booking.user?.acro_role?.name === null;
          }).length ?? 0,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#000000"],
      },
    ],
  };
  // const [
  //   runCancelMutation,
  //   { loading: isCancelMutationLoading, data: cancelMutationData },
  // ] = useMutation<UpdateClassEventResponse>(ClassEventsMutations.Cancel);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const closeCancelDialog = () => {
  //   setIsDialogOpen(false);
  //   setClassEventToCancel(undefined);
  // };

  const columns: GridColDef<ClassEventBookingsFieldsFragmentType>[] = [
    {
      field: "check_in",
      headerName: "Check in",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        const classEvent = params.row.class_event;
        // return 2 icons: check in box, options
        return (
          // a row
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip
              title={
                "check in the customer to keep track of who attended the event"
              }
            >
              {/* check in icon */}
              <div>
                <IconButton
                  color="inherit"
                  aria-label="check in"
                  edge="start"
                  onClick={() => {}}
                >
                  <CheckBoxIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "user_name",
      headerName: "Customer",
      width: 160,
      valueGetter: ({ row }) => {
        return row.user?.name;
      },
    },
    {
      field: "booking_option",
      headerName: "Booking Option",
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.booking_option?.title;
      },
    },
    {
      field: "booking_date",
      headerName: "Booking Date",
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.created_at.toLocaleString(DateTime.DATETIME_MED, {
          locale: "de",
        });
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => {
        return row.user?.email;
      },
    },
    {
      field: "more",
      headerName: "",
      sortable: false,
      width: 40,
      renderCell: (params) => {
        const classEvent = params.row.class_event;
        // return 2 icons: check in box, options
        return (
          // a row
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* options */}
            <Tooltip title={"More options"}>
              {/* options icon */}
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => {}}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  return (
    <Box className="container">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1} // Material-UI spacing for margin-bottom, adjust the value as needed
        mt={2}
      >
        <h1
          style={{
            margin: 0,
            padding: 0,
            fontSize: "2.5em",
            fontWeight: "bold",
          }}
        >
          {bookings?.[0].class_event?.class?.name}
        </h1>
      </Box>

      <h2 style={{ margin: 3, fontSize: "1.5em", fontWeight: "normal" }}>
        {bookings?.[0].class_event?.start_date.toLocaleString(
          DateTime.DATETIME_MED,
          {
            locale: "de",
          }
        )}{" "}
        -{" "}
        {bookings?.[0].class_event?.end_date.toLocaleString(
          DateTime.TIME_24_SIMPLE,
          {
            locale: "de",
          }
        )}
      </h2>

      {/* Show a centered row with three items seperated by a vertical line */}
      {/* show the amount of successfull bookings as participants */}
      <Box padding={4}>
        <BookingOverviewRow
          items={[
            {
              title: "Participants",
              subtitle: successfulBookings?.length.toString() || "0",
            },
            {
              title: "Total revenue",
              subtitle: (totalRevenue?.toString() || "0") + " €",
            },
            { title: "Status", subtitle: "Open for sale" },
          ]}
        />
      </Box>

      <Box pb={4}>
        <PieRow data1={pieData1} data2={pieData2} />
      </Box>

      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isLoading}
          rows={data ? data.class_event_bookings : []}
          rowCount={
            data ? data.class_event_bookings_aggregate.aggregate?.count : 0
          }
          columns={columns}
          pageSize={pageSize}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          checkboxSelection={false}
          paginationMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
        />
      </div>
    </Box>
  );
};
