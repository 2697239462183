import "./user-select.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Alert } from "@mui/material";
import { User } from "../../../models/user.type";
import { useUsersPageable } from "../../../hooks/graphql/use-users-pageable-query";
import { Where } from "../../../models/where.model";

interface UserSelectProps {
  value: User | undefined;
  disabled?: boolean;
  where?: Where<User>;
  noOptionsText?: string;
  onChange: (users: User | undefined) => void;
}

const UserSelect = ({
  value,
  disabled,
  where,
  noOptionsText,
  onChange,
}: UserSelectProps) => {
  const { loading, error, data } = useUsersPageable({
    limit: 50,
    offset: 0,
    where: where,
  });
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  return (
    <div>
      <Autocomplete
        multiple
        autoHighlight
        noOptionsText={noOptionsText}
        options={data?.users || []}
        loading={loading}
        disabled={disabled}
        value={value ? [value] : []}
        onChange={(_, newValue) => {
          if (newValue.length === 0) {
            onChange(undefined);
          } else if (newValue.length === 1) {
            onChange(newValue[0]);
          } else if (newValue.length === 2) {
            onChange(newValue[1]);
          }
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name || ""}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Box key={`user-tag-${option.id}`} className="user-option">
              <img
                className="user-option-image"
                loading="lazy"
                src={option?.image_url}
                alt=""
              />
              <p className="user-name">{option?.name}</p>
            </Box>
          ));
        }}
        renderOption={(props, option) => {
          return (
            <Box
              className="user-option"
              component="li"
              {...props}
              key={`user-option-${option.id}`}
            >
              <img
                className="user-image"
                loading="lazy"
                src={option?.image_url}
                alt=""
              />
              <p className="user-name">{option?.name}</p>
            </Box>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Choose user" />}
      />
    </div>
  );
};
export default UserSelect;
