import {
  Box,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import { DateTime } from "luxon";
import AddIcon from "@mui/icons-material/Add";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { StorageKeys } from "../../../storage/storage-keys.enum";
import {
  EventFieldsFragmentType,
  Events_Bool_ExpType,
  Teachers_Bool_ExpType,
  useGetEventsPageableQuery,
} from "../../../__generated___/gql";

const stringFilterOperators = getGridStringOperators().filter(({ value }) =>
  ["contains"].includes(value)
);

const CustomToolbar = () => {
  return <GridToolbarContainer></GridToolbarContainer>;
};

export const AdminEvents = () => {
  const navigate = useNavigate();
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);
  const [showPastEvents, setShowPastEvents] = useLocalStorage<boolean>(
    StorageKeys.ShowPastEvents,
    false
  );
  const [where, setWhere] = useState<Events_Bool_ExpType>({});
  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetEventsPageableQuery({
    variables: {
      limit: limit,
      offset: offset,
      where: where,
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
      where: {
        ...where,
        ...(!showPastEvents && { end_date: { _gt: "now()" } }),
      },
    });
  }, [offset, where, showPastEvents]);

  const columns: GridColDef<EventFieldsFragmentType>[] = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit">
            <Link to={`/app/events/${params.row.id}`} className="icon-link">
              <IconButton color="inherit" aria-label="open drawer" edge="start">
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 75,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={params.row?.main_image_url}
          alt=""
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      filterOperators: stringFilterOperators,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        return row.start_date.toLocaleString(DateTime.DATETIME_MED);
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        return row.end_date.toLocaleString(DateTime.DATETIME_MED);
      },
    },
    {
      field: "confirmation_status",
      headerName: "Confirmation Status",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box className="container">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Events</h1>
        <Fab
          onClick={() => {
            navigate(`/app/events/create`);
          }}
          variant="extended"
          color="primary"
          aria-label="add"
        >
          <AddIcon />
          Create Event
        </Fab>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showPastEvents}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShowPastEvents(event.target.checked);
              }}
            />
          }
          label="Show past events"
        />
      </FormGroup>

      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isQueryLoading}
          rows={data ? data.events : []}
          rowCount={data ? data.events_aggregate?.aggregate?.count : 0}
          columns={columns}
          pageSize={pageSize}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          checkboxSelection={false}
          paginationMode="server"
          filterMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          onFilterModelChange={(filterModel) => {
            console.log("filterModel", filterModel);
            const conditionalOperatorMap: Record<string, string> = {
              and: "_and",
              or: "_or",
            };
            const operatorMap: Record<string, string> = {
              contains: "_like",
              is: "_eq",
            };
            const where: Teachers_Bool_ExpType = {
              ...(filterModel.linkOperator && {
                [conditionalOperatorMap[filterModel.linkOperator]]:
                  filterModel.items
                    .filter((item) => item.value)
                    .map((item) => ({
                      [item.columnField]: {
                        ...(item.operatorValue && {
                          [operatorMap[item.operatorValue]]: `${
                            item.value || ""
                          }`,
                        }),
                      },
                    })),
              }),
            };
            setWhere(where);
          }}
        />
      </div>
    </Box>
  );
};
