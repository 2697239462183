import { gql } from "@apollo/client";
import Fragments from "../fragments";

export default class TeacherMutations {
  public static Insert = gql`
    mutation Insert(
      $userId: uuid!
      $name: String!
      $description: String!
      $is_organization: Boolean!
      $instagram_name: String
      $signup_message: String
      $type: teacher_type_enum!
      $images: [teacher_images_insert_input!]!
    ) {
      insert_teachers(
        objects: {
          name: $name
          type: $type
          description: $description
          instagram_name: $instagram_name
          signup_message: $signup_message
          is_organization: $is_organization
          user_id: $userId
          images: { data: $images }
        }
      ) {
        affected_rows
      }
    }
  `;

  public static Delete = gql`
    mutation DeleteTeacher($id: uuid!) {
      delete_user_roles(
        where: { user: { teacher_profile: { id: { _eq: $id } } } }
      ) {
        affected_rows
      }
      delete_images(where: { teacher_image: { image_id: { _eq: $id } } }) {
        affected_rows
      }
      delete_teacher_images(where: { teacher_id: { _eq: $id } }) {
        affected_rows
      }
      delete_class_owners(where: { teacher_id: { _eq: $id } }) {
        affected_rows
      }
      delete_class_events(
        where: {
          class: {
            class_owners: { teacher_id: { _eq: $id } }
            class_owners_aggregate: { count: { predicate: { _eq: 1 } } }
          }
        }
      ) {
        affected_rows
      }
      delete_classes(
        where: {
          class_owners: { teacher_id: { _eq: $id } }
          class_owners_aggregate: { count: { predicate: { _eq: 1 } } }
        }
      ) {
        affected_rows
      }
      delete_teachers_by_pk(id: $id) {
        id
      }
    }
  `;
}
