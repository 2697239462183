import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetEventsPageableQuery } from "../../../__generated___/gql";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { StorageKeys } from "../../../storage/storage-keys.enum";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { ScrollContainer } from "../../../components/scroll-container/scroll-container";

export const TeacherEvents = () => {
  const navigate = useNavigate();
  const pageSize = 50;
  const limit = pageSize;
  const [showPastEvents, setShowPastEvents] = useLocalStorage<boolean>(
    StorageKeys.ShowPastEvents,
    false
  );
  const {
    loading: isQueryLoading,
    data,
    refetch,
  } = useGetEventsPageableQuery({
    variables: {
      limit: limit,
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      where: {
        ...(!showPastEvents && { end_date: { _gt: "now()" } }),
      },
    });
  }, [showPastEvents]);

  const hasAtLeastOneEvent = data?.events_aggregate?.aggregate?.count !== 0;

  return (
    <ScrollContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Events</h1>
        {hasAtLeastOneEvent && (
          <Fab
            onClick={() => {
              navigate(`/app/events/create`);
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
            Create Event
          </Fab>
        )}
      </Box>
      {hasAtLeastOneEvent && (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={showPastEvents}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setShowPastEvents(event.target.checked);
                }}
              />
            }
            label="Show past events"
          />
        </FormGroup>
      )}

      {isQueryLoading ? (
        <CircularProgress />
      ) : hasAtLeastOneEvent ? (
        <Grid container spacing={2}>
          {data?.events.map((event) => (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={event.main_image_url}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {event.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="description"
                  >
                    {event.start_date.toFormat("dd.MM.yyyy")} -{" "}
                    {event.end_date.toFormat("dd.MM.yyyy")}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="description"
                  >
                    {event.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      navigate(`/app/events/${event.id}`);
                    }}
                  >
                    Edit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}></Grid>
          <Grid
            item
            xs={12}
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="10px"
          >
            <Typography textAlign="center">No events yet</Typography>
            <Fab
              onClick={() => {
                navigate(`/app/events/create`);
              }}
              variant="extended"
              color="primary"
              aria-label="add"
            >
              <AddIcon />
              Create Events
            </Fab>
          </Grid>
        </Grid>
      )}
    </ScrollContainer>
  );
};
