import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Event } from "../../../graphql/type-mapping/graphql-types.mapping";
import { TeacherSlim } from "../../../models/teacher-slim.model";
import {
  Confirmation_Status_EnumType,
  Event_Type_EnumType,
  useGetEventSlugSuggestionLazyQueryType,
  useInviteMutation,
} from "../../../__generated___/gql";
import AddressSearch from "../../adress-search/address-search";
import { setIsGlobaLoadingVisible } from "../../global-loading-overlay/global-loading-overlay";
import ImageInput from "../../image-input/image-input";
import TeacherSelect from "../../teachers/teacher-select/teacher-select";
import logo from "../../../assets/highlighted-events.png";
import tz_lookup from "tz-lookup";
import { ConfirmationStatus } from "../../../models/confirmation-status.model";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../../global-notification-overlay/global-notification-overlay";
import { InviteModel } from "../../../models/invite.model";
import { Invites } from "../../invites/invites";
import { useIsEventSlugAvailableLazyQueryType } from "../../../__generated___/gql";
import UrlSlugInput from "../../url-slug-input/url-slug-input";
import { EventFormField } from "../enums/event-form-field";

interface EventViewProps {
  event: Partial<Event>;
  isAdminView?: boolean;
  isEditView?: boolean;
  onNewImageSet: (profileImageAsBase64: string) => void;
  onEventUpdated: (clazz: Partial<Event>) => void;
  addFormError: (key: string, message: string) => void;
  removeFormError: (key: string) => void;
}

const normalizeDate = (dt: DateTime): DateTime => {
  return dt.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
};

export const EventView = ({
  event,
  isAdminView,
  isEditView,
  onNewImageSet,
  onEventUpdated,
  addFormError,
  removeFormError,
}: EventViewProps) => {
  console.log("event", event);
  const [idInput, setIdInput] = useState(event.id);
  const [nameInput, setNameInput] = useState(event.name);
  const [startDate, setStartDate] = useState<DateTime>(
    normalizeDate(event.start_date || DateTime.now())
  );
  const [endDate, setEndDate] = useState<DateTime>(
    normalizeDate(event.end_date || DateTime.now())
  );

  const [longitudeInput, setLongitudeInput] = useState<string>(
    event?.location?.coordinates[0].toString() || ""
  );
  const [latitudeInput, setLatitudeInput] = useState<string>(
    event?.location?.coordinates[1].toString() || ""
  );
  const [descriptionInput, setDescriptionInput] = useState(
    event?.description || ""
  );
  const [cityInput, setCityInput] = useState(event?.location_city || "");
  const [countryInput, setCountryInput] = useState(
    event?.location_country || ""
  );
  const [pricingInput, setPricingInput] = useState(event?.pricing || "");
  const [imageSource, setImageSource] = useState(event?.main_image_url);
  const [eventType, setEventType] = useState(
    event?.event_type || Event_Type_EnumType.FestivalsAndConsType
  );
  const [urlInput, setUrlInput] = useState(event?.url);
  const [owners, setOwners] = useState<TeacherSlim[]>(event.owners || []);
  const [teachers, setTeachers] = useState<TeacherSlim[]>(event.teachers || []);

  const [open, setOpen] = useState(false);
  const [highlightRequestedInput, setHighlightRequestedInput] = useState(
    event.highlight_requested
  );
  const [confirmationStatus, setConfirmationStatus] = useState<
    Confirmation_Status_EnumType | undefined
  >(event?.confirmation_status);
  const [timezone, setTimezone] = useState("");
  const [ownerInvites, setOwnerInvites] = useState<InviteModel[]>(
    event?.invites?.filter((invite) => invite.entity === "event") || []
  );
  const [teacherInvites, setTeacherInvites] = useState<InviteModel[]>(
    event?.invites?.filter((invite) => invite.entity === "event_teacher") || []
  );
  const [createdBy, setCreatedBy] = useState<Event["created_by"] | undefined>(
    event.created_by
  );
  const [invitedEmail, setInvitedEmail] = useState("");
  const [
    inviteMutation,
    {
      data: inviteMutationData,
      error: inviteMutationError,
      loading: isInviteMutationLoading,
    },
  ] = useInviteMutation();
  const [isUrlSlugAvailableQuery] = useIsEventSlugAvailableLazyQueryType({
    fetchPolicy: "network-only",
  });
  const [getSlugSuggestionQuery] = useGetEventSlugSuggestionLazyQueryType({
    fetchPolicy: "network-only",
  });
  const [urlSlugInput, setUrlSlugInput] = useState(event?.url_slug);

  const callGetSlugSuggestion = async () => {
    if (urlSlugInput) {
      return;
    }
    setIsGlobaLoadingVisible({ isOpen: true, text: "Loading suggestion" });
    const { data } = await getSlugSuggestionQuery({
      variables: { name: nameInput as string },
    });
    if (data?.get_event_slug_suggestion) {
      setUrlSlugInput(data.get_event_slug_suggestion);
    }
    setIsGlobaLoadingVisible({ isOpen: false });
  };

  useEffect(() => {
    if (event?.name) {
      callGetSlugSuggestion();
    }
  }, []);

  useEffect(() => {
    if (inviteMutationData?.invite.success) {
      setGlobalSuccessNotification(`Invited ${invitedEmail}`);
      setOwnerInvites([...ownerInvites, { email: invitedEmail }]);
    }
  }, [inviteMutationData]);

  useEffect(() => {
    setIsGlobaLoadingVisible({ isOpen: isInviteMutationLoading });
  }, [isInviteMutationLoading]);

  useEffect(() => {
    if (inviteMutationError) {
      setGlobalErrorNotification(inviteMutationError?.message);
    }
  }, [inviteMutationError]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculatePayAmount = (): number => {
    const days = endDate.diff(normalizeDate(DateTime.now()), "days").days;
    const discount = days >= 90 ? 0.8 : days >= 30 ? 0.9 : 1;
    const price = days * 0.5 * discount;
    return price > 0 ? price : 0;
  };

  useEffect(() => {
    const longitude = parseFloat(longitudeInput);
    const latitude = parseFloat(latitudeInput);
    let timezone = undefined;
    if (longitudeInput && latitudeInput) {
      timezone = tz_lookup(latitude, longitude);
      setTimezone(timezone);
    }

    onEventUpdated({
      id: idInput,
      name: nameInput,
      description: descriptionInput,
      pricing: pricingInput,
      event_type: eventType,
      url: urlInput,
      start_date: startDate,
      end_date: endDate,
      start_date_tz: timezone
        ? normalizeDate(startDate.setZone(timezone))
        : undefined,
      end_date_tz: timezone
        ? normalizeDate(endDate.setZone(timezone))
        : undefined,
      location:
        !Number.isNaN(longitude) && !Number.isNaN(latitude)
          ? {
              type: "Point",
              coordinates: [longitude, latitude],
            }
          : undefined,
      location_city: cityInput,
      location_country: countryInput,
      teachers: teachers,
      main_image_url: imageSource,
      highlight_requested: highlightRequestedInput,
      is_highlighted: event.is_highlighted,
      confirmation_status: confirmationStatus,
      owners: owners,
      invites: [
        ...ownerInvites.map((invite) => ({ ...invite, entity: "event" })),
        ...teacherInvites.map((invite) => ({
          ...invite,
          entity: "event_teacher",
        })),
      ],
      created_by: createdBy,
      timezone: timezone,
      url_slug: urlSlugInput,
    });
  }, [
    idInput,
    nameInput,
    descriptionInput,
    pricingInput,
    eventType,
    urlInput,
    startDate,
    endDate,
    longitudeInput,
    latitudeInput,
    cityInput,
    countryInput,
    teachers,
    imageSource,
    highlightRequestedInput,
    event.is_highlighted,
    confirmationStatus,
    owners,
    ownerInvites,
    teacherInvites,
    createdBy,
    timezone,
    urlSlugInput,
  ]);

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>
          <h1>Event Highlights</h1>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <h2>Gain visibility</h2>
              <p>
                On the right side you can see how the highlighted events look in
                the app. Highlighted events are displayed at the top and
                emphasized by a different background color. It offers you a
                low-cost way to promote your event and gain visibility.
              </p>
              <h2>How does it work</h2>
              <p>
                To highlight your event, we ask you for a small amount to
                support the development of the app. The amount will be
                calculated based on the time the event is highlighted. We
                provide a 10% discount if you submit your event 30 days before
                the end date, and 20% if you submit the event 90 days before its
                end.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={logo} width="100%" alt="" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} marginBottom="5px">
          {event.created_by && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="5px"
            >
              <Typography>created by</Typography>
              <Box className="teacher-option-wrapper">
                <img
                  className="small-teacher-image"
                  loading="lazy"
                  src={
                    event.created_by?.teacher_profile?.images?.find(
                      (image) => image.is_profile_picture
                    )?.image.url || event.created_by?.image_url
                  }
                  alt=""
                />
                <Box className="teacher-info">
                  <p>
                    {event.created_by?.teacher_profile?.name ||
                      event.created_by?.name}
                  </p>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
        {isAdminView && confirmationStatus && (
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="test-select-label">
                Confirmation Status
              </InputLabel>
              <Select
                labelId="test-select-label"
                label="Confirmation Status"
                value={confirmationStatus}
                onChange={(event) => {
                  setConfirmationStatus(
                    event.target.value as Confirmation_Status_EnumType
                  );
                }}
                fullWidth
              >
                <MenuItem value={ConfirmationStatus.Confirmed}>
                  {ConfirmationStatus.Confirmed}
                </MenuItem>
                <MenuItem value={ConfirmationStatus.Pending}>
                  {ConfirmationStatus.Pending}
                </MenuItem>
                <MenuItem value={ConfirmationStatus.Rejected}>
                  {ConfirmationStatus.Rejected}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <h2>Event owners</h2>
          <p>
            Event owners are members of AcroWorld that have editing privileges
            for this event. Here you can add people to organize the event with.
          </p>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TeacherSelect
            teachers={owners}
            label="Other event owners"
            onChange={(newOwners) => {
              console.log("newOwners", newOwners);
              setOwners(newOwners);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} marginBottom={2}>
          <h4>Invited organizers</h4>
          <p>
            Here you can invite people that are not yet members of AcroWorld to
            be an owner for this event.
          </p>
          <Invites
            existingInvites={ownerInvites}
            buttonLabel={"Invite event owner"}
            getTooltipTitle={(invite) => {
              return invite.id
                ? "Invite was sent successfully"
                : `Invite is pending. It will be sent out when you ${
                    event?.id ? "update" : "create"
                  } the event.`;
            }}
            onInvitesUpdate={(invites) => setOwnerInvites(invites)}
          />
        </Grid>
        <Grid item xs={12} sm={12} position="relative">
          <Box className="profile-image-wrapper">
            <ImageInput
              sources={imageSource ? [imageSource] : []}
              onImagesLoaded={(sources: string[]) => {
                setIsGlobaLoadingVisible({ isOpen: false });
                setImageSource(sources[0]);
                onNewImageSet(sources[0]);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <h2>General information</h2>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Name"
            type="text"
            variant="outlined"
            value={nameInput}
            required
            fullWidth
            onBlur={() => {
              callGetSlugSuggestion();
            }}
            onChange={(event) => {
              setNameInput(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <UrlSlugInput
            required={true}
            urlSlug={urlSlugInput}
            onUrlSlugUpdate={(urlSlug: string) => {
              setUrlSlugInput(urlSlug);
            }}
            hint={`In the future we will use the url slug in the url to your event. (e.g. acroworld.de/events/${
              urlSlugInput ? urlSlugInput : "<your-url-slug>"
            })`}
            isUrlSlugAvailable={async (urlSlug: string) => {
              const queryResult = await isUrlSlugAvailableQuery({
                variables: {
                  urlSlug,
                },
              });
              const isAvailable =
                (urlSlug !== "" && queryResult.data?.is_event_slug_available) ??
                false;
              if (!isAvailable) {
                addFormError(
                  EventFormField.UrlSlug,
                  "Url slug is not available"
                );
              } else {
                removeFormError(EventFormField.UrlSlug);
              }
              return isAvailable;
            }}
          ></UrlSlugInput>
        </Grid>
        <Grid item xs={12} sm={12}>
          <h3>Start and end date</h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Start Date"
            value={startDate}
            disableMaskedInput
            onChange={(newValue) => {
              if (newValue) {
                const diff = endDate.diff(startDate, "days");
                const normalizedDate = normalizeDate(newValue);
                setStartDate(normalizedDate);
                setEndDate(normalizedDate.plus({ days: diff.days }));
              }
            }}
            renderInput={(params) => (
              <TextField required fullWidth {...params} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="End Date"
            value={endDate}
            minDate={startDate}
            disableMaskedInput
            onChange={(newValue) => {
              if (newValue) {
                setEndDate(normalizeDate(newValue));
              }
            }}
            renderInput={(params) => (
              <TextField required fullWidth {...params} />
            )}
          />
        </Grid>
        {!isAdminView && !event.is_highlighted && (
          <Grid item xs={12} sm={12}>
            <div>
              We offer you the possibility to promote your event by highlighting
              it in our app. Highlighted events are displayed until their end
              date at the top and can be easily distinguished from the others.{" "}
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Click here to learn more.
              </Link>
            </div>
          </Grid>
        )}
        {!event.is_highlighted && (
          <Grid className="row" item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={highlightRequestedInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setHighlightRequestedInput(event.target.checked);
                  }}
                />
              }
              label=""
            />
            {highlightRequestedInput ? (
              <div>
                {calculatePayAmount() === 0 && (
                  <p>
                    To use highlighting the events end date must be in the
                    future
                  </p>
                )}
                {calculatePayAmount() > 0 && (
                  <p>
                    You have selected to highlight your event. To confirm your
                    request, send {calculatePayAmount().toFixed(2)}€ via PayPal
                    to{" "}
                    <Link
                      href="https://paypal.me/acroworld?country.x=DE&locale.x=de_DE"
                      target="_blank"
                    >
                      paypal.me/acroworld
                    </Link>{" "}
                    and include the name and the date of your event so we can
                    identify it. As soon as we receive the payment and checked
                    the event we will highlight it. Thank you for your support!
                    If you have any questions, feel free to contact us at{" "}
                    <Link href="mailto:info@acroworld.de" target="_blank">
                      info@acroworld.de
                    </Link>
                  </p>
                )}
              </div>
            ) : (
              <p>Click here to highlight your event in our app</p>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <h3>Event teachers</h3>
          <p>
            Here you can add or invite teachers that teach at your event. Adding
            all teachers here will increase the visibility of your event and
            make it easier for users of AcroWorld to find your event.
          </p>
          <TeacherSelect
            teachers={teachers}
            label={"Add teachers participating at your event"}
            onChange={(teachers: TeacherSlim[]) => {
              setTeachers(teachers);
            }}
          ></TeacherSelect>
        </Grid>
        <Grid item xs={12} sm={12} marginBottom={2}>
          <h3>Invited teachers</h3>
          <p>
            Here you can invite teachers that are not yet members of AcroWorld
            to teach at this event.
          </p>
          <Invites
            existingInvites={teacherInvites}
            buttonLabel={"Invite event teacher"}
            getTooltipTitle={(invite) => {
              return invite.id
                ? "Invite was sent successfully"
                : `Invite is pending. It will be sent out when you ${
                    event?.id ? "update" : "create"
                  } the event.`;
            }}
            onInvitesUpdate={(invites) =>
              setTeacherInvites(
                invites.map((invite) => ({
                  ...invite,
                  entity: "event_teacher",
                }))
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Description"
            type="text"
            variant="outlined"
            multiline
            minRows={12}
            maxRows={12}
            value={descriptionInput}
            onChange={(event) => setDescriptionInput(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Pricing"
            type="text"
            variant="outlined"
            multiline
            minRows={3}
            maxRows={12}
            value={pricingInput}
            onChange={(event) => setPricingInput(event.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="test-select-label">Event Type</InputLabel>
            <Select
              labelId="test-select-label"
              label="Event Type"
              value={eventType}
              onChange={(event) => {
                setEventType(event.target.value as Event_Type_EnumType);
              }}
              defaultValue={Event_Type_EnumType.FestivalsAndConsType}
              fullWidth
            >
              <MenuItem value={Event_Type_EnumType.FestivalsAndConsType}>
                {Event_Type_EnumType.FestivalsAndConsType}
              </MenuItem>
              <MenuItem value={Event_Type_EnumType.RetreatsType}>
                {Event_Type_EnumType.RetreatsType}
              </MenuItem>
              <MenuItem value={Event_Type_EnumType.TrainingsType}>
                {Event_Type_EnumType.TrainingsType}
              </MenuItem>
              <MenuItem value={Event_Type_EnumType.WorkshopsType}>
                {Event_Type_EnumType.WorkshopsType}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Url"
            type="text"
            variant="outlined"
            value={urlInput}
            fullWidth
            onChange={(event) => {
              setUrlInput(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AddressSearch
            value={
              longitudeInput && latitudeInput
                ? {
                    coordinates: {
                      longitude: parseFloat(longitudeInput),
                      latitude: parseFloat(latitudeInput),
                    },
                    city: cityInput,
                  }
                : undefined
            }
            onPlaceUpdate={(place) => {
              setLongitudeInput(place.coordinates.longitude.toString());
              setLatitudeInput(place.coordinates.latitude.toString());
              setCityInput(place.city || "");
              setCountryInput(place.country || "");
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
