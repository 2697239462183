import "./delete-account.scss";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import {
  useDeleteAccountMutation,
  useLoginMutation,
} from "../../__generated___/gql";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import logo from "../../assets/logo.png";
import { setGlobalErrorNotification } from "../global-notification-overlay/global-notification-overlay";

const DeleteAccount = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [mutateFunction] = useLoginMutation({
    fetchPolicy: "network-only",
  });
  const [deleteAccountMutation] = useDeleteAccountMutation();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleDeleteAccount = async () => {
    console.log("calling mutate");
    setLoading(true);
    try {
      const loginResult = await mutateFunction({
        variables: {
          email: emailInput,
          password: passwordInput,
        },
      });
      console.log("result", loginResult);
      const deleteResult = await deleteAccountMutation({
        context: {
          headers: { Authorization: `Bearer ${loginResult.data?.login.token}` },
        },
      });
      console.log("deleteResult", deleteResult);
      if (deleteResult.data?.delete_account) {
        setSuccess(true);
      }
    } catch (e: any) {
      console.log(JSON.stringify(e));
      setGlobalErrorNotification(e.graphQLErrors?.[0]?.message);
    } finally {
      setLoading(false);
      setIsDialogOpen(false);
    }
  };

  return (
    <div className="App">
      <LoadingOverlay isOpen={loading} />
      <div className="App-header">
        {success ? (
          <Box>
            <Alert severity="success">Account was deleted</Alert>
          </Box>
        ) : (
          <FormControl className="login-input-wrapper">
            <Box display="flex" justifyContent="center">
              <img src={logo} width={100} alt="AcroWorld Logo" />
            </Box>
            <Typography
              padding={1}
              style={{
                fontSize: "0.75rem",
              }}
            >
              If you want to delete your account input your username and
              password and click on "Delete Account"
            </Typography>
            <Alert severity="warning">
              This will permanently remove all of your data and cannot be undone
            </Alert>
            <TextField
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              onChange={(event) => setEmailInput(event.target.value)}
            />
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={(event) => setPasswordInput(event.target.value)}
            />
            <Button
              color="error"
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
            >
              Delete Account
            </Button>
          </FormControl>
        )}

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle>Delete Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete your account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDeleteAccount}
              color="error"
              variant="contained"
            >
              Confirm Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default DeleteAccount;
