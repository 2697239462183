import React from "react";
import { Pie } from "react-chartjs-2";
import { PieChartData } from "../../models/pie-chart.type";

interface PieChartProps {
  data: PieChartData;
  title: string;
}

const PieChart: React.FC<PieChartProps> = ({ data, title }) => {
  return (
    <div>
      <h3>{title}</h3>
      <Pie data={data} />
    </div>
  );
};

export default PieChart;
