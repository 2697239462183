import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { BookingOptionModel } from "../../../../models/booking-option.model";
import CurrencySelector, {
  CURRENCY_OPTIONS,
} from "../../../currency-selector/currency-selector";
import FloatNumberInput from "../../../float-number-input/float-number-input";
import React, { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

interface BookingOptionInputProps {
  option: BookingOptionModel;
  onChange: (bookingOption: BookingOptionModel) => void;
}

const COMMISSION_PERCENTAGE_AMOUNT = 0.05;
const COMMISSION_CENT_AMOUNT = 50;

const BookingOptionInput = ({ option, onChange }: BookingOptionInputProps) => {
  const commissionTotalAmount = option.price
    ? Math.max(
        Math.round(option.price * COMMISSION_PERCENTAGE_AMOUNT),
        Math.round(option.price * 0.015 + COMMISSION_CENT_AMOUNT)
      )
    : 0;

  const [wasPriceChanged, setWasPriceChanged] = useState(false);

  const currencySymbol = CURRENCY_OPTIONS.find(
    (currencyOption) => currencyOption.value === option.currency
  )?.symbol;

  return (
    <Grid container spacing={2} className="container">
      <Grid item xs={12}>
        <Typography>
          Fill out the form to create or edit a booking option
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth={true} required>
          <InputLabel htmlFor="title">Title</InputLabel>
          <OutlinedInput
            id="title"
            label="Name"
            margin="dense"
            value={option.title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const title = event.target.value;
              onChange({
                ...option,
                title,
              });
            }}
          />
          <FormHelperText id="title-helper-text">
            The title of your booking option
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="subtitle">Subtitle</InputLabel>
          <OutlinedInput
            id="subtitle"
            label="Subtitle"
            margin="dense"
            value={option.subtitle}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const subtitle = event.target.value;
              onChange({
                ...option,
                subtitle,
              });
            }}
          />
          <FormHelperText id="subtitle-helper-text">
            The subtitle
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth={true} required>
          <FloatNumberInput
            value={option.price ? option.price / 100 : undefined}
            error={wasPriceChanged && (option.price ?? 0) < 100}
            label="Price"
            onChange={(value: number) => {
              setWasPriceChanged(true);
              onChange({
                ...option,
                price: Math.round(value * 100),
              });
            }}
          />

          <FormHelperText id="component-error-text">
            {wasPriceChanged && (option.price ?? 0 < 100)
              ? `Price must be higher must at least 1.00 ${currencySymbol}`
              : "The cost of this booking option"}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <CurrencySelector
          value={option.currency}
          onSelect={(currency) => {
            onChange({
              ...option,
              currency: currency.value,
            });
          }}
        />
      </Grid>

      <Grid item xs={12} display="flex" gap="5px" alignItems="center">
        <InfoOutlined />
        {option.price ? (
          <Typography>
            Service and payment fee of {commissionTotalAmount / 100}
            {currencySymbol}
            per booking.
          </Typography>
        ) : (
          <Typography>
            AcroWorld will take a service and payment fee per booking. Set a
            price to calculate it.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default BookingOptionInput;
