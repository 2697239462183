import { useEffect, useState } from "react";
import { Form } from "../../screens/gatherings/models/form.model";

interface FormHookResult {
  touched: boolean;
  hasError: boolean;
}

export function useForm<T>(form: Form<T>): FormHookResult {
  const [touched, setTouched] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const isTouched = Object.keys(form).some(
      (key: string) => form[key as keyof T].touched
    );
    const hasErrors = Object.keys(form).some((key: string) => {
      console.log(
        "form[key as keyof T].errors",
        form[key as keyof T].errors,
        Object.keys(form[key as keyof T].errors ?? {}).length > 0
      );
      return Object.keys(form[key as keyof T].errors ?? {}).length > 0;
    });

    console.log("hasErrors", hasErrors, Object.keys(form));

    setTouched(isTouched);
    setHasError(hasErrors);
  }, [form]);

  return { touched, hasError };
}
