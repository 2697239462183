import { RoleName } from "../enums/role.enum";
import { useRoles } from "../hooks/use-roles";

interface RoleAwareProps {
  visibleFor: RoleName[];
  children: JSX.Element;
}

const RoleAware = ({ visibleFor, children }: RoleAwareProps) => {
  const roles = useRoles();
  const isVisible = visibleFor.some((role) => roles.includes(role));
  if (isVisible) {
    return children;
  }
  return null;
};

export default RoleAware;
