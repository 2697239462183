import { gql } from "@apollo/client";

import Fragments from "./fragments";
export default class Queries {
  public static GetMe = gql`
    query GetMe {
      me {
        ${Fragments.User}
      }
    }
  `;

  public static GetLevels = gql`
    query GetLevels {
      levels {
        id
        name
      }
    }
  `;

  public static GetTeachers = gql`
    query GetTeachers {
      teachers {
        ${Fragments.Teacher}
      }
    }
  `;

  public static GetTeacherPageable = gql`
    query GetTeacherPageable($limit: Int, $offset: Int)  {
      teachers(limit: $limit, offset: $offset) {
        ${Fragments.Teacher}
        confirmation_status
      }
      teachers_aggregate {
        aggregate {
          count
        }
      }
    }
  `;

  public static GetTeacherByPk = gql`
    query GetTeacherByPk($id: uuid!) {
      teachers_by_pk(id: $id) {
        ${Fragments.Teacher}
      }
    }
  `;

  public static GetCommunities = gql`
    query GetCommunites {
      communities {
        id
        name
      }
    }
  `;

  public static GetUsers = gql`
    query GetUsers {
      users {
        id
        name
        image_url
      }
    }
  `;

  public static GetClasses = gql`
    query GetClasses($limit: Int, $offset: Int) {
      classes(limit: $limit, offset: $offset, order_by: { name: asc }) {
        city
        name
        pricing
        location_name
        location
        image_url
        id
        description
        class_pass_url
        requirements
        usc_url
        is_classe
        website_url
        url_slug
        recurring_patterns {
          id
          start_time
          end_time
          day_of_week
          created_at
          class_id
        }
      }
      classes_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
    }
  `;

  public static GetClassByPk = gql`
    query GetClassByPk($id: uuid!) {
      classes_by_pk(id: $id) {
        id
        city
        name
        pricing
        location
        location_name
        location_city
        location_country
        event_type
        image_url
        description
        class_pass_url
        requirements
        usc_url
        is_classe
        timezone
        website_url
        url_slug
        max_booking_slots
        created_by_id
        created_by {
          teacher_profile {
            id
            name
            location_name
            is_stripe_enabled
            images {
              id
              is_profile_picture
              image {
                id
                url
              }
            }
          }
        }
        invites(where: { confirmation_status: { _eq: Pending } }) {
          id
          email
          entity
          confirmation_status
          invited_user {
            id
            name
            teacher_profile {
              id
              name
              images {
                id
                is_profile_picture
                image {
                  id
                  url
                }
              }
            }
          }
        }
        class_owners {
          id
          is_payment_receiver
          teacher {
            id
            name
            location_name
            is_stripe_enabled
            images {
              id
              is_profile_picture
              image {
                id
                url
              }
            }
          }
        }
        class_teachers {
          teacher {
            id
            name
            location_name
            is_stripe_enabled
            images {
              id
              is_profile_picture
              image {
                id
                url
              }
            }
          }
        }
        class_levels {
          level {
            id
            name
          }
        }
        class_booking_options {
          booking_option {
            id
            title
            subtitle
            price
            currency
            discount
          }
        }
        recurring_patterns {
          id
          start_time
          end_time
          day_of_week
          created_at
          class_id
          start_date
          end_date
          recurring_every_x_weeks
          is_recurring
          is_end_date_final @client
        }
      }
    }
  `;
}
