import { makeVar, useReactiveVar } from "@apollo/client";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";

export interface GlobalLoadingOverlayConfig {
  isOpen: boolean;
  text?: string;
}

export const setIsGlobaLoadingVisible = makeVar<GlobalLoadingOverlayConfig>({
  isOpen: false,
  text: "",
});

function GlobalLoadingOverlay() {
  const globalOverlayConfig = useReactiveVar(setIsGlobaLoadingVisible);
  return (
    <LoadingOverlay
      isOpen={globalOverlayConfig.isOpen}
      text={globalOverlayConfig.text}
    />
  );
}
export default GlobalLoadingOverlay;
