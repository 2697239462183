import { useEffect, useState } from "react";
import { FormField } from "../../screens/gatherings/models/form-field.model";

export function useFormErrorChecking<T>(
  form: Record<string, FormField<T>>
): boolean {
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  useEffect(() => {
    const formHasErrors = Object.values(form).some(
      (field) => !!getFirstError(field)
    );
    setHasErrors(formHasErrors);
  }, [form]);

  return hasErrors;
}

export function useFieldErrorChecking<T>(field?: FormField<T>): boolean {
  return !!getFirstError(field);
}

export function useGetFirstError<T>(field?: FormField<T>): string | undefined {
  return getFirstError(field);
}

function getFirstError<T>(field?: FormField<T>): string | undefined {
  if (field && field.errors) {
    const errorKeys = Object.keys(field.errors);
    if (errorKeys.length > 0) {
      return field.errors[errorKeys[0]];
    }
  }
  return undefined;
}
