import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import BookingOptionInput from "../booking-option-input/booking-option-input";
import { BookingOptionModel } from "../../../../models/booking-option.model";

interface EditBookingOptionDialogProps {
  option: BookingOptionModel;
  onSave: (option: BookingOptionModel) => void;
  onCancel: () => void;
}

export const EditBookingOptionDialog = ({
  option,
  onSave,
  onCancel,
}: EditBookingOptionDialogProps) => {
  const [bookingOptionInput, setBookingOptionInput] =
    useState<BookingOptionModel>(option);
  return (
    <Dialog open={true}>
      <DialogTitle>Add booking option</DialogTitle>
      <DialogContent>
        <BookingOptionInput
          option={bookingOptionInput}
          onChange={(bookingOption: BookingOptionModel) => {
            setBookingOptionInput(bookingOption);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            !bookingOptionInput?.price ||
            bookingOptionInput?.price < 100 ||
            !bookingOptionInput.title
          }
          onClick={() => {
            onSave(bookingOptionInput);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
