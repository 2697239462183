import { Box, Container, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  ClassEventBookingsFieldsFragmentType,
  Confirmation_Status_EnumType,
} from "../../../__generated___/gql";
import { DateTime } from "luxon";
import { useGetAllBookingsPageableQuery } from "../../../__generated___/gql";
import { ViewList } from "@mui/icons-material";
import { CURRENCY_OPTIONS } from "../../../components/currency-selector/currency-selector";

export const AllBookings = () => {
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);

  const {
    loading: isQueryLoading,
    error,
    data,
    refetch,
  } = useGetAllBookingsPageableQuery({
    variables: {
      limit,
      offset,
      where: { status: { _eq: Confirmation_Status_EnumType.ConfirmedType } },
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  const columns: GridColDef<ClassEventBookingsFieldsFragmentType>[] = [
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 75,
      renderCell: (params) => {
        const classEvent = params.row.class_event;
        // return 2 icons: check in box, options
        return (
          // a row
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip title="See bookings for this specific class event">
              <Link
                to={`/app/classes/${classEvent?.class?.id}/events/${classEvent?.id}/bookings`}
                className="icon-link"
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                >
                  <ViewList />
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "class_event.class",
      headerName: "Class Name",
      width: 200,
      sortable: false,
      renderCell: (params) => params.row.class_event?.class?.name,
    },

    {
      field: "booking_option",
      headerName: "Booking Option",
      width: 230,
      sortable: false,
      renderCell: (params) => params.row.booking_option?.title,
    },
    {
      field: "user_name",
      headerName: "Customer",
      width: 150,
      sortable: false,
      renderCell: (params) => params.row.user?.name,
    },

    {
      field: "class_date",
      headerName: "Date of Class",
      width: 190,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        return `${row.class_event?.start_date.toFormat(
          "dd.MM.yy HH:mm"
        )} - ${row.class_event?.end_date.toFormat("HH:mm")}`;
      },
    },
    {
      field: "created_at",
      headerName: "Booking Date",
      width: 160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        const dateTime = DateTime.fromISO(row.created_at);
        const formattedDate = dateTime.toFormat("dd.MM.yy HH:mm");

        return formattedDate;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      sortable: false,
      renderCell: (params) =>
        `${params.row.amount / 100} ${
          CURRENCY_OPTIONS.find(
            (currencyOption) => params.row.currency === currencyOption.value
          )?.symbol
        }`,
    },
  ];

  return (
    <Container>
      <h1>Bookings overview</h1>
      <p>Here you can see all boogkings for your upcoming event occurences</p>
      <div style={{ height: "65vh", width: "100%" }}>
        <DataGrid
          error={error}
          loading={isQueryLoading}
          rows={data ? data.class_event_bookings : []}
          rowCount={
            data ? data.class_event_bookings_aggregate.aggregate?.count : 0
          }
          columns={columns}
          pageSize={pageSize}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          checkboxSelection={false}
          paginationMode="server"
          rowsPerPageOptions={[pageSize]}
          onPageChange={(page) => {
            setOffset(page * pageSize);
          }}
        />
      </div>
    </Container>
  );
};
