import "./create-teacher-form.scss";
import { Alert, Box, Button, Grid, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UpdateResponse } from "../../../graphql/models/responses/update-response.model";
import { LoadingOverlay } from "../../../components/loading-overlay/loading-overlay";
import { FileUtils } from "../../../lib/utils/file-utils";
import { Firebase } from "../../../lib/firebase";
import TeacherMutations from "../../../graphql/teachers/teacher-mutations";
import { Teacher } from "../../../models/teacher.type";
import { TeacherView } from "../../../components/teachers/teacher/teacher";
import { useNavigate } from "react-router-dom";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../../../components/global-notification-overlay/global-notification-overlay";
import { Defaults } from "../../../defaults";
import { useRoles } from "../../../hooks/use-roles";
import { RoleName } from "../../../enums/role.enum";
import { setMe } from "../../../reactive-vars/me";
import { useReactiveVar } from "@apollo/client";
import { FormError } from "../../../models/form-error.model";
import { ScrollContainer } from "../../../components/scroll-container/scroll-container";

interface CreateTeacherFormProps {
  userId?: string;
  isIntroductionForm?: boolean;
  onFinish?: () => void;
}

export const CreateTeacherForm = ({
  userId,
  isIntroductionForm,
  onFinish,
}: CreateTeacherFormProps) => {
  const navigate = useNavigate();
  const roles = useRoles();
  const me = useReactiveVar(setMe);
  const [
    mutateFunction,
    { data, loading: isInsertMutationLoading, error: insertMutationError },
  ] = useMutation<UpdateResponse<"insert_teachers">>(TeacherMutations.Insert);
  const [value, setValue] = useState<Partial<Teacher>>();

  const [profileImageSource, setProfileImageSource] = useState<string>();
  const [imagesAsBase64, setImagesAsBase64] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);

  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  useEffect(() => {
    setIsLoading(isImageUploadLoading || isInsertMutationLoading);
  }, [isImageUploadLoading, isInsertMutationLoading]);

  useEffect(() => {
    setGlobalErrorNotification(insertMutationError?.message || "");
  }, [insertMutationError]);

  useEffect(() => {
    if (data) {
      setGlobalSuccessNotification("Success!");
      if (onFinish) {
        onFinish();
      } else {
        navigate("/app/teachers");
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setIsErrorVisible(true);
    }
  }, [error]);

  useEffect(() => {
    console.log("CreateTeacherForm:me", me);
    if (me) {
      setValue({
        name: me.name,
      });
    }
  }, [me]);

  if (value && !value.name) {
    return <LoadingOverlay />;
  }

  return (
    <ScrollContainer>
      <h1>Create an AcroWorld Teacher Profile</h1>
      <Snackbar
        open={isErrorVisible}
        autoHideDuration={5000}
        onClose={() => {
          setError("");
          setIsErrorVisible(false);
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <LoadingOverlay isOpen={isLoading} />
      {!isIntroductionForm && <h1>Create Teacher</h1>}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TeacherView
            value={value}
            isIntroductionForm={isIntroductionForm}
            isAdminView={roles.includes(RoleName.AdminUser)}
            onNewProfileImageSet={(imageAsBase64: string | undefined) => {
              setProfileImageSource(imageAsBase64);
            }}
            onNewImagesSet={(imagesAsBase64: string[]) => {
              setImagesAsBase64(imagesAsBase64);
            }}
            onTeacherUpdated={(newValue: Partial<Teacher>) => {
              setValue(newValue);
            }}
            addFormError={(key: string, message: string) => {
              setFormErrors([...formErrors, { key, message }]);
            }}
            removeFormError={(key: string) => {
              const index = formErrors.findIndex((error) => error.key === key);
              if (index > -1) {
                formErrors.splice(index, 1);
                setFormErrors([...formErrors]);
              }
            }}
          ></TeacherView>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={async () => {
              if (!value) {
                return;
              }
              if (!value.user && !userId) {
                setError("Set a user");
                return;
              }
              if (formErrors.length > 0) {
                setGlobalErrorNotification(formErrors[0].message);
                return;
              }
              const imagesInput: {
                is_profile_picture: boolean;
                image: { data: { url: string } };
              }[] = [];
              try {
                setIsImageUploadLoading(true);
                let uploadedProfileImageUrl;
                if (
                  profileImageSource &&
                  FileUtils.isBase64(profileImageSource)
                ) {
                  const profileImageAsBlob =
                    await FileUtils.convertBase64ToBlob(profileImageSource);
                  uploadedProfileImageUrl = await Firebase.uploadFile(
                    profileImageAsBlob
                  );
                } else {
                  uploadedProfileImageUrl = Defaults.TeacherProfileImageUrl;
                }

                const imagesAsBlobs = await Promise.all(
                  imagesAsBase64.map((image) =>
                    FileUtils.convertBase64ToBlob(image)
                  )
                );
                const uploadedFilesUrls = await Firebase.uploadFiles(
                  imagesAsBlobs
                );
                if (uploadedProfileImageUrl) {
                  imagesInput.push({
                    is_profile_picture: true,
                    image: { data: { url: uploadedProfileImageUrl } },
                  });
                }

                if (uploadedFilesUrls.length > 0) {
                  imagesInput.push(
                    ...uploadedFilesUrls.map((url) => ({
                      is_profile_picture: false,
                      image: { data: { url: url } },
                    }))
                  );
                }
                const createVariables = {
                  variables: {
                    ...value,
                    name: value.name,
                    signup_message: value.signup_message,
                    images: imagesInput,
                    levels: value?.teacher_levels?.map((teacher_level) => ({
                      level_id: teacher_level.level.id,
                    })),
                    userId: value?.user?.id || userId,
                  },
                };
                mutateFunction(createVariables);
              } catch (e: any) {
                setError(e.message);
              } finally {
                setIsImageUploadLoading(false);
              }
            }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </ScrollContainer>
  );
};
