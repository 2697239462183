import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { RecurringPatternModel } from "../../../../models/recurring-pattern.model";
import { CYCLE_OPTIONS } from "../cycle-options";
import { DAY_OF_WEEK_OPTIONS } from "../day-options";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import { useState } from "react";

interface RecurringPatternProps {
  pattern: RecurringPatternModel;
  onEditButtonClick: () => void;
  onDeleteButtonClick: () => void;
}
export const RecurringPattern = ({
  pattern,
  onEditButtonClick,
  onDeleteButtonClick,
}: RecurringPatternProps) => {
  const startDate = pattern.start_date || pattern.created_at;
  const endDate = pattern.end_date;
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const isDeleted = pattern.wasMarkedForDeletion;
  const isActive = pattern.id && !pattern.wasUpdated && !isDeleted;
  const isPending = (!pattern.id || pattern.wasUpdated) && !isDeleted;

  return (
    <Paper
      style={{
        opacity: isDeleted ? 0.75 : 1,
        backgroundColor: isDeleted
          ? "red"
          : isPending
          ? "lightblue"
          : isActive
          ? "lightgreen"
          : undefined,
      }}
    >
      <Dialog open={isDeleteConfirmationDialogOpen}>
        <DialogTitle>Remove occurence pattern? </DialogTitle>
        <DialogContent>
          {pattern.is_recurring && (
            <DialogContentText>
              Are you sure you want to remove this occurence pattern? This will
              remove all your classes occurences beginning from now:
              <Typography fontWeight="bold" display="flex" gap="5px">
                {
                  CYCLE_OPTIONS.find(
                    (option) => option.value === pattern.recurring_every_x_weeks
                  )?.key
                }{" "}
                on {DAY_OF_WEEK_OPTIONS[pattern.day_of_week - 1]},{" "}
                {pattern.start_time.toFormat("HH:mm")} -{" "}
                {pattern.end_time.toFormat("HH:mm")}
              </Typography>
            </DialogContentText>
          )}
          {!pattern.is_recurring && (
            <DialogContentText>
              Are you sure you want to remove this single occurence? This will
              remove the following class occurence:
              <Typography fontWeight="bold" display="flex" gap="5px">
                On {DAY_OF_WEEK_OPTIONS[pattern.day_of_week - 1]}{" "}
                {startDate?.toFormat("dd.MM.yy")} between{" "}
                {pattern.start_time.toFormat("HH:mm")} -{" "}
                {pattern.end_time.toFormat("HH:mm")}
              </Typography>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteConfirmationDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsDeleteConfirmationDialogOpen(false);
              onDeleteButtonClick();
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        <Box display="flex" flexDirection="column" gap="5px">
          {pattern.is_recurring && (
            <Box display="flex" flexDirection="column" gap="5px">
              <Box display="flex" alignItems="center" gap="5px">
                <CalendarViewWeekIcon />
                <Typography fontWeight="bold">
                  Occuring{" "}
                  {CYCLE_OPTIONS.find(
                    (option) => option.value === pattern.recurring_every_x_weeks
                  )?.key?.toLowerCase()}{" "}
                  on {DAY_OF_WEEK_OPTIONS[pattern.day_of_week - 1]},{" "}
                  {pattern.start_time.toFormat("HH:mm")} -{" "}
                  {pattern.end_time.toFormat("HH:mm")}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <CalendarMonthIcon />
                <Typography display="flex" gap="5px">
                  {`From ${startDate?.toFormat("dd.MM.yy")} ${
                    pattern.end_date
                      ? `until ${pattern.end_date.toFormat("dd.MM.yy")}`
                      : ""
                  }`}
                </Typography>
              </Box>
            </Box>
          )}
          {!pattern.is_recurring && (
            <Box display="flex" flexDirection="column" gap="5px">
              {" "}
              <Box display="flex" alignItems="center" gap="5px">
                <CalendarViewWeekIcon />
                <Typography fontWeight="bold">Occuring once</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <CalendarMonthIcon />
                <Typography>
                  {" "}
                  {startDate?.toFormat("dd.MM.yy")}{" "}
                  {pattern.start_time.toFormat("HH:mm")} -{" "}
                  {endDate?.toFormat("dd.MM.yy")}{" "}
                  {pattern.end_time.toFormat("HH:mm")}
                </Typography>
              </Box>
            </Box>
          )}

          {isActive && (
            <Tooltip title="This occurence pattern was created and is active">
              <Box display="flex" alignItems="center" gap="5px">
                <CheckIcon />
                <Typography>Active</Typography>
              </Box>
            </Tooltip>
          )}

          {isPending && (
            <Box display="flex" alignItems="center" gap="5px">
              <PendingActionsIcon />
              <Typography>
                Occurence pattern was created/updated. Save the class to set it
                active.
              </Typography>
            </Box>
          )}

          {isDeleted && (
            <Box display="flex" alignItems="center" gap="5px">
              <DeleteIcon />
              <Typography>
                This occurence pattern marked for deletion. Save the class to
                finally remove it.
              </Typography>
            </Box>
          )}
        </Box>

        {!pattern.wasMarkedForDeletion && (
          <Box display="flex">
            <IconButton
              onClick={() => {
                onEditButtonClick();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                if (pattern.id) {
                  setIsDeleteConfirmationDialogOpen(true);
                } else {
                  onDeleteButtonClick();
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
