import "./Login.scss";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { FormControl } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Confirmation_Status_EnumType,
  useGetInviteByPkLazyQueryType,
  useLoginMutation,
  useResetPasswordMutation,
} from "../../__generated___/gql";
import logo from "../../assets/logo.png";
import {
  setGlobalErrorNotification,
  setGlobalInfoNotification,
  setGlobalSuccessNotification,
} from "../../components/global-notification-overlay/global-notification-overlay";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { AcroworldJwt } from "../../models/acroworld-jwt.type";
import { useJwt } from "react-jwt";
import { useUrlQuery } from "../../hooks/use-query";
import Invite from "../../components/invite/invite";
import { sleep } from "../../components/protected-route/protected-route";
import { ScrollContainer } from "../../components/scroll-container/scroll-container";

const Login = () => {
  const navigate = useNavigate();
  const urlQuery = useUrlQuery();
  const inviteId = urlQuery.get("inviteId") || undefined;
  const redirectTo = urlQuery.get("redirectTo") || undefined;
  console.log("inviteId", inviteId);
  const [
    getInvite,
    { error: getInviteError, loading: isGetInviteLoading, data: getInviteData },
  ] = useGetInviteByPkLazyQueryType();

  const [emailInput, setEmailInput] = useState("");
  const [resetPasswordEmailInput, setResetPasswordEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [mutateFunction, { data, loading, error }] = useLoginMutation({
    fetchPolicy: "network-only",
  });
  const [resetPasswordMutation] = useResetPasswordMutation();
  const [jwtToken, setJwtToken] = useLocalStorage("jwt-token", "");
  const [refreshToken, setRefreshToken] = useLocalStorage("refresh-token", "");
  const { isExpired } = useJwt<AcroworldJwt>(jwtToken);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClickOpen = () => {
    if (emailInput) {
      setResetPasswordEmailInput(emailInput);
    }
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  console.log("Login:data", data);
  console.log("Login:loading", loading);
  console.log("Login:jwtToken", jwtToken);
  console.log("Login:isExpired", isExpired);

  useEffect(() => {
    if (inviteId) {
      getInvite({
        variables: {
          id: inviteId,
        },
      });
    }
  }, []);

  if (getInviteError) {
    setGlobalErrorNotification(getInviteError.message);
  }

  if (loading) {
    return <LoadingOverlay isOpen={true} />;
  }

  if (!loading && data && !error) {
    setJwtToken(data.login.token);
    setRefreshToken(data.login.refreshToken);
    window.location.reload();
  }
  if (
    getInviteData &&
    (getInviteData.invite_by_id.confirmation_status ===
      Confirmation_Status_EnumType.ConfirmedType ||
      getInviteData.invite_by_id.confirmation_status ===
        Confirmation_Status_EnumType.RejectedType)
  ) {
    setGlobalInfoNotification(
      `This invite is already ${getInviteData.invite_by_id.confirmation_status.toLowerCase()}.`
    );
    sleep(3000);
    return <Navigate to={"/login"} />;
  }
  if (jwtToken && !isExpired) {
    console.log("navigate to app");
    return <Navigate to={redirectTo ? redirectTo : "/app"} />;
  }

  return (
    <ScrollContainer>
      <LoadingOverlay isOpen={loading || isGetInviteLoading} />
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>
              To reset your password please put in your email and we will send a
              reset link.
            </span>{" "}
            <span style={{ fontWeight: "bold" }}>
              This link will expire after one hour.
            </span>
          </DialogContentText>
          <Box marginTop="10px" marginBottom="10px">
            <TextField
              autoFocus
              fullWidth
              label="Email Address"
              type="email"
              value={resetPasswordEmailInput}
              onChange={(event) => {
                setResetPasswordEmailInput(event.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            flex={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const result = await resetPasswordMutation({
                    variables: { email: resetPasswordEmailInput },
                  });
                  if (result.data?.reset_password.success) {
                    setGlobalSuccessNotification(
                      "If that email is in our system we will send a reset password email to it."
                    );
                  }
                  handleClose();
                } catch (e) {
                  setGlobalErrorNotification(`Something wen't wrong: ${e}`);
                }
              }}
            >
              Reset Password
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <div className="App-header">
        <FormControl className="login-input-wrapper">
          {error && <Alert severity="error">{error.message}</Alert>}
          <Box display="flex" justifyContent="center">
            <img src={logo} width={inviteId ? 100 : 200} alt="AcroWorld Logo" />
          </Box>
          {inviteId && (
            <Box>
              <Box display="flex" justifyContent="center">
                <Invite
                  loading={isGetInviteLoading}
                  invite={getInviteData?.invite_by_id}
                />
              </Box>

              {!getInviteError && (
                <Typography
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  Register or login to accept the invite
                </Typography>
              )}
            </Box>
          )}
          <Typography
            padding={1}
            style={{
              fontSize: "0.75rem",
            }}
          >
            You can login with your AcroWorld account
          </Typography>
          <TextField
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            onChange={(event) => setEmailInput(event.target.value)}
          />
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={(event) => setPasswordInput(event.target.value)}
          />
          <Button
            variant="contained"
            onClick={() => {
              console.log("calling mutate");
              mutateFunction({
                variables: {
                  email: emailInput,
                  password: passwordInput,
                },
              });
            }}
          >
            Login
          </Button>
          <Button
            style={{
              fontSize: "0.75rem",
            }}
            onClick={() => {
              handleClickOpen();
            }}
          >
            Forgot your password?
          </Button>
          <Divider>
            <Chip label="OR" />
          </Divider>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/register?${urlQuery.toString()}`);
            }}
          >
            Register as teacher
          </Button>
        </FormControl>
      </div>
    </ScrollContainer>
  );
};

export default Login;
