import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  FormControl,
  Grid,
  StepButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import GeneralGatheringInformation from "./general-gathering-information/general-gathering-information";
import ImageInput from "../../../components/image-input/image-input";
import { ScrollContainer } from "../../../components/scroll-container/scroll-container";
import { GatheringType } from "./enums/gathering-type.enum";
import { useEffect, useState } from "react";
import { ClassAsTeacher } from "../../../__generated__/gql-teacher";
import TeacherSelect from "../../../components/teachers/teacher-select/teacher-select";
import { Form } from "../models/form.model";
import { useForm } from "../../../hooks/forms/use-form";

const typeConfig = {
  [GatheringType.Festival]: {
    description:
      "A large-scale event featuring multiple acroyoga sessions, performances, and community gatherings, usually lasting several days.",
  },
  [GatheringType.Class]: {
    description:
      "A regular, structured session where participants learn and practice acroyoga techniques, suitable for all levels.",
  },
  [GatheringType.Workshop]: {
    description:
      "A focused session led by an expert, aimed at teaching specific acroyoga techniques or concepts in a short period.",
  },
  [GatheringType.Jam]: {
    description:
      "An informal gathering where acroyoga practitioners share skills, practice together, and learn from each other without a set structure.",
  },
  [GatheringType.Retreat]: {
    description:
      "A multi-day event that combines acroyoga sessions with other activities like meditation and wellness, in a relaxing environment away from daily life.s",
  },
  [GatheringType.Training]: {
    description:
      "An intensive program designed to deepen participants' acroyoga practice or prepare them to teach, spanning several days to weeks.",
  },
};

export type ClassForm = Form<Partial<ClassAsTeacher>>;
export type GeneralClassInformationForm = Pick<
  ClassForm,
  "name" | "url_slug" | "description"
>;

export default function CreateGathering() {
  const [type, setType] = useState(GatheringType.Festival);
  const [activeStep, setActiveStep] = useState(0);
  const [gathering, setGathering] = useState<Partial<ClassAsTeacher>>({});

  const [generalClassInformationForm, setGeneralClassInformationForm] =
    useState<GeneralClassInformationForm>({
      name: { value: "", errors: { required: "Please provide a value" } },
      url_slug: { value: "", errors: { required: "Please provide a value" } },
      description: {
        value: "",
        errors: { required: "Please provide a value" },
      },
    });

  const {
    touched: isGeneralClassInformationTouched,
    hasError: hasGeneralClassInformationError,
  } = useForm(generalClassInformationForm);

  console.log(
    "hasGeneralClassInformationError",
    hasGeneralClassInformationError
  );

  useEffect(() => {}, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <ScrollContainer>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="type">
          <StepButton color="inherit" onClick={handleStep(0)}>
            Type {activeStep !== 0 && `(${type})`}
          </StepButton>
          <StepContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography>
                  The type of gathering you want to create. Depending on your
                  selection some different information is required.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth={true}>
                  <ToggleButtonGroup
                    color="success"
                    fullWidth
                    exclusive
                    orientation="vertical"
                    value={type}
                    onChange={(_, newValue) => {
                      setType(newValue);
                    }}
                  >
                    {Object.keys(typeConfig).map((type) => {
                      return (
                        <ToggleButton
                          key={type}
                          style={{ height: 56, display: "flex", gap: "5px" }}
                          value={type}
                        >
                          <Avatar
                            sx={{ bgcolor: "#212121", width: 30, height: 30 }}
                          >
                            {type[0]}
                          </Avatar>
                          <Typography>{type}</Typography>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography>
                  Currently type "{type}" is selected.{" "}
                  {typeConfig?.[type]?.description}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="general-information">
          <StepButton color="inherit" onClick={handleStep(1)}>
            General Information
          </StepButton>
          <StepContent>
            <GeneralGatheringInformation
              type={type}
              form={generalClassInformationForm}
              onFormUpdate={(partiallyUpdatedForm: ClassForm) => {
                console.log("partiallyUpdatedForm", partiallyUpdatedForm);
                setGeneralClassInformationForm({
                  ...generalClassInformationForm,
                  ...partiallyUpdatedForm,
                });
              }}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    !isGeneralClassInformationTouched ||
                    hasGeneralClassInformationError
                  }
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="teachers">
          <StepLabel>Teachers</StepLabel>
          <StepContent>
            <Grid item xs={12} sm={12} position="relative">
              <TeacherSelect
                label="Add owner/s"
                teachers={[]}
                onChange={(newTeachers) => {}}
              />
            </Grid>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="image">
          <StepLabel>Image</StepLabel>
          <StepContent>
            <Grid item xs={12} sm={12} position="relative">
              <Box className="profile-image-wrapper">
                <ImageInput
                  sources={[]}
                  onImagesLoaded={(sources: string[]) => {
                    // setIsGlobaLoadingVisible({ isOpen: false });
                    // setImageSource(sources[0]);
                  }}
                />
              </Box>
            </Grid>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="location">
          <StepLabel optional={false}>Location</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="additional-information">
          <StepLabel optional={false}>Additional information</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Submit
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </ScrollContainer>
  );
}
