import "./Dashboard.scss";
import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoleName } from "../../enums/role.enum";
import RoleAware from "../../components/role-aware";
import { useMe } from "../../components/protected-route/protected-route";
import InvitesDialog from "../../dialogs/invites-dialog/invites-dialog";
import { ScrollContainer } from "../../components/scroll-container/scroll-container";

const Dashboard = () => {
  const navigate = useNavigate();
  const { me } = useMe();

  return (
    <ScrollContainer>
      <h1>Welcome</h1>
      <RoleAware visibleFor={[RoleName.TeacherUser]}>
        <Box>
          <InvitesDialog></InvitesDialog>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => {
                  navigate(`/app/teachers/${me?.teacher_profile?.id}`);
                }}
              >
                My Teacher Profile
              </Button>
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => {
                  navigate("/app/classes");
                }}
              >
                Events
              </Button>
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => {
                  navigate("/app/invites");
                }}
              >
                Invites
              </Button>
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => {
                  navigate("/app/bookings");
                }}
              >
                Bookings
              </Button>
            </Grid>
          </Grid>
        </Box>
      </RoleAware>

      <RoleAware visibleFor={[RoleName.AdminUser]}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => {
                navigate("/app/users");
              }}
            >
              Users
            </Button>
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => {
                navigate("/app/classes");
              }}
            >
              Events
            </Button>
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => {
                navigate("/app/teachers");
              }}
            >
              Teachers
            </Button>
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => {
                navigate("/app/events");
              }}
            >
              Events
            </Button>
          </Grid> */}

          <Grid item xs={12} md={4}>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => {
                navigate("/app/invites");
              }}
            >
              Invites
            </Button>
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => {
                navigate("/app/bookings");
              }}
            >
              Bookings
            </Button>
          </Grid>
        </Grid>
      </RoleAware>
    </ScrollContainer>
  );
};

export default Dashboard;
