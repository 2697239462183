import Resizer from "react-image-file-resizer";
import heic2any from "heic2any";

export class FileUtils {
  public static handleFileChosen = async (file: File) => {
    return new Promise<string>((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e?.target?.result) {
          resolve(e.target.result as string);
        }
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  };

  public static convertHeicToJpg = async (file: File): Promise<Blob> => {
    const normalizedFileName = file.name?.toLocaleLowerCase();
    const isHeic =
      normalizedFileName?.endsWith(".heic") ||
      normalizedFileName?.endsWith(".heif");

    let fileAsBlob;
    if (isHeic) {
      let blobURL = URL.createObjectURL(file);
      const heicFileAsBlob = await (await fetch(blobURL)).blob();
      fileAsBlob = (await heic2any({
        blob: heicFileAsBlob,
        toType: "image/jpeg",
      })) as Blob;
    } else {
      const readFile = await FileUtils.handleFileChosen(file);
      fileAsBlob = await FileUtils.convertBase64ToBlob(readFile);
    }
    if (!fileAsBlob) {
      throw new Error("Error converting file to Blob");
    }
    return fileAsBlob;
  };

  public static readSingleFile = async (file: File): Promise<string> => {
    return await FileUtils.resizeFile(await FileUtils.convertHeicToJpg(file));
  };

  public static readAllFiles = async (allFiles: FileList) => {
    const filesAsBase64: string[] = [];
    for (let i = 0; i < allFiles.length; i++) {
      const file = allFiles[i];
      filesAsBase64.push(await FileUtils.readSingleFile(file));
    }
    const results = await Promise.all(filesAsBase64);

    return results;
  };

  public static convertBase64ToBlob = async (
    fileAsBase64: string
  ): Promise<Blob> => {
    return (await fetch(fileAsBase64)).blob();
  };

  public static resizeFile = async (fileAsBlob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        fileAsBlob,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri as string);
        },
        "base64"
      );
    });
  };

  public static isBase64 = (src: string): boolean => {
    console.log("isBase64", src);
    return typeof src === "string" && src.startsWith("data:image");
  };
}
