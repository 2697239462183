import "./teacher-classes-view.scss";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useGetClassesPageableQuery } from "../../__generated___/gql";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import { ScrollContainer } from "../../components/scroll-container/scroll-container";
import ReactHtmlParser from "react-html-parser";

export const TeacherClassesView = () => {
  const navigate = useNavigate();
  const pageSize = 50;
  const limit = pageSize;
  const [offset, setOffset] = useState(0);
  const {
    loading: isQueryLoading,
    data,
    refetch,
  } = useGetClassesPageableQuery({
    variables: {
      limit: limit,
      offset: offset,
    },
  });

  useEffect(() => {
    refetch({
      limit: limit,
      offset: offset,
    });
  }, [offset]);

  const hasAtLeastOneClass = data?.classes_aggregate?.aggregate?.count !== 0;

  return (
    <ScrollContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Events</h1>
        {hasAtLeastOneClass && (
          <Fab
            onClick={() => {
              navigate(`/app/classes/create`);
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
            Create New
          </Fab>
        )}
      </Box>
      {isQueryLoading ? (
        <CircularProgress />
      ) : hasAtLeastOneClass ? (
        <Grid container spacing={2}>
          {data?.classes.map((clazz) => (
            <Grid key={clazz.id} item xs={12} sm={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={clazz.image_url}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {clazz.name}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <Button
                    size="small"
                    startIcon={<CalendarMonth />}
                    onClick={() => {
                      navigate(`/app/classes/${clazz.id}/events`);
                    }}
                  >
                    Occurences
                  </Button>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      navigate(`/app/classes/${clazz.id}`);
                    }}
                  >
                    Edit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}></Grid>
          <Grid
            item
            xs={12}
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="10px"
          >
            <Typography textAlign="center">No events or classes yet</Typography>
            <Fab
              onClick={() => {
                navigate(`/app/classes/create`);
              }}
              variant="extended"
              color="primary"
              aria-label="add"
            >
              <AddIcon />
              Create New
            </Fab>
          </Grid>
        </Grid>
      )}
    </ScrollContainer>
  );
};
