import "./address-search.scss";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { KeyboardEvent, SyntheticEvent, useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Coordinates } from "../../models/coordinates.type";
import { Place } from "../../models/place.type";
import SearchIcon from "@mui/icons-material/Search";
import { setGlobalErrorNotification } from "../global-notification-overlay/global-notification-overlay";

interface OsmPlace {
  place_id: number;
  display_name: string;
  lon: string;
  lat: string;
  address: {
    county?: string;
    state?: string;
    city?: string;
    village?: string;
    town?: string;
    country: string;
  };
}

const RecenterAutomatically = ({ latitude, longitude }: Coordinates) => {
  const map = useMap();
  useEffect(() => {
    map.setView([latitude, longitude]);
  }, [latitude, longitude, map]);
  return null;
};

const MapEvents = ({
  setLocation,
}: {
  setLocation: (location: Coordinates) => void;
}) => {
  useMapEvents({
    click: (e) => {
      setLocation({
        longitude: e.latlng.lng,
        latitude: e.latlng.lat,
      });
    },
  });
  return null;
};

interface AddressSearchProps {
  value?: Place;
  onPlaceUpdate: (place: Place) => void;
}

const AddressSearch = ({ value, onPlaceUpdate }: AddressSearchProps) => {
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OsmPlace[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedOsmLocation, setSelectedOsmLocation] = useState<OsmPlace>();
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>(
    value ? value.coordinates : undefined
  );

  const onChange = (_: unknown, newValue: OsmPlace | string | null) => {
    if (newValue) {
      setSelectedOsmLocation(newValue as OsmPlace);
    }
  };

  const onInputChange = async (
    event: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setInputText(value);
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    setOptions([]);
  }, [inputText]);

  useEffect(() => {
    if (selectedOsmLocation && selectedOsmLocation.address) {
      const newLocation = {
        longitude: parseFloat(selectedOsmLocation.lon),
        latitude: parseFloat(selectedOsmLocation.lat),
      };
      setCoordinates(newLocation);
      onPlaceUpdate({
        coordinates: newLocation,
        city:
          selectedOsmLocation.address.city ||
          selectedOsmLocation.address.town ||
          selectedOsmLocation.address.village ||
          selectedOsmLocation.address.state ||
          selectedOsmLocation.address.county,
        country: selectedOsmLocation.address.country,
      });
    }
  }, [selectedOsmLocation]);

  useEffect(() => {
    if (coordinates) {
      searchByLocation(coordinates.latitude, coordinates.longitude);
    }
  }, []);

  const searchByLocation = async (latitude: number, longitude: number) => {
    setIsLoading(true);
    const res = await fetch(
      `https://nominatim.openstreetmap.org/reverse?` +
        encodeURI(
          `lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`
        ),
      {
        headers: {
          "Accept-Charset": "UTF-8",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Accept-Language": "en-US",
        },
      }
    );
    const json = await res.json();
    if (json.error) {
      setGlobalErrorNotification(json.error);
    } else {
      setSelectedOsmLocation(json as OsmPlace);
    }
    setIsLoading(false);
  };

  const searchByInputText = async () => {
    setIsLoading(true);
    const res = await fetch(
      `https://nominatim.openstreetmap.org/search?` +
        encodeURI(`q=${inputText}&format=json&addressdetails=1`),
      {
        headers: {
          "Accept-Charset": "UTF-8",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Accept-Language": "en-US",
        },
      }
    );
    const json: OsmPlace[] = await res.json();
    setIsLoading(false);
    setOptions(json);
  };

  const onKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.code === "Enter") {
      searchByInputText();
    }
  };

  return (
    <Box position="relative" display="flex" flexDirection="column">
      <Autocomplete
        className="autocomplete-input"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(x) => x}
        loading={isLoading}
        disablePortal
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.display_name
        }
        onFocus={() => setOpen(true)}
        options={options}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onInputChange={onInputChange}
        isOptionEqualToValue={(option, value) =>
          option.place_id === value.place_id
        }
        noOptionsText={
          "Input a search term and hit enter or click on the icon to search for it"
        }
        renderOption={(props, option) => {
          return (
            <li key={option.place_id} {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: "text.secondary", mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {option.display_name}
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Search"
              onFocus={() => setOpen(true)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => searchByInputText()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
        clearIcon={false}
        forcePopupIcon={false}
      />
      <MapContainer
        style={{
          height: "400px",
          width: "100%",
        }}
        center={[52.51910169869303, 13.40702284836524]}
        zoom={7.5}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapEvents
          setLocation={(coordinates: Coordinates) => {
            searchByLocation(coordinates.latitude, coordinates.longitude);
          }}
        />
        {coordinates && (
          <Box>
            <RecenterAutomatically
              latitude={coordinates?.latitude}
              longitude={coordinates?.longitude}
            />
            <Marker
              position={[coordinates.latitude, coordinates.longitude]}
            ></Marker>
          </Box>
        )}
      </MapContainer>
    </Box>
  );
};
export default AddressSearch;
