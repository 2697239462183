import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { BookingOptionModel } from "../../../../models/booking-option.model";
import { CURRENCY_OPTIONS } from "../../../currency-selector/currency-selector";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

interface BookingOptionProps {
  option: BookingOptionModel;
  onEditButtonClick: () => void;
  onDeleteButtonClick: () => void;
}

export const BookingOption = ({
  option,
  onEditButtonClick,
  onDeleteButtonClick,
}: BookingOptionProps) => {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const currency = CURRENCY_OPTIONS.find(
    (currencyOption) => currencyOption.value === option.currency
  );
  const isDeleted = option.wasMarkedForDeletion;
  const isActive = option.id && !option.wasUpdated && !isDeleted;
  const isPending = (!option.id || option.wasUpdated) && !isDeleted;

  return (
    <Paper
      style={{
        opacity: isDeleted ? 0.75 : 1,
        backgroundColor: isDeleted
          ? "red"
          : isPending
          ? "lightblue"
          : isActive
          ? "lightgreen"
          : undefined,
      }}
    >
      <Dialog open={isDeleteConfirmationDialogOpen}>
        <DialogTitle>Remove booking option?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the booking option:
            <Typography fontWeight="bold" display="flex" gap="5px">
              {option.title} - {option.subtitle} ({option.currency}
              {option.price})
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteConfirmationDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsDeleteConfirmationDialogOpen(false);
              onDeleteButtonClick();
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        <Box display="flex" flexDirection="column" gap="5px">
          <Box display="flex" alignItems="center" gap="5px">
            <Typography fontWeight="bold">{option.title}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography fontStyle="italic">{option.subtitle}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="5px">
            <Typography>
              Price:{" "}
              {((option.price! / 100) * (1 - option.discount! / 100)).toFixed(
                2
              )}{" "}
              {currency?.symbol}{" "}
              {option.discount! > 0
                ? `(incl. ${option.discount}% discount)`
                : ""}
            </Typography>
          </Box>

          {isActive && (
            <Tooltip title="This occurence pattern was created and is active">
              <Box display="flex" alignItems="center" gap="5px">
                <CheckIcon />
                <Typography>Active</Typography>
              </Box>
            </Tooltip>
          )}

          {isPending && (
            <Box display="flex" alignItems="center" gap="5px">
              <PendingActionsIcon />
              <Typography>
                This booking option was created/updated. Save the class to set
                it active.
              </Typography>
            </Box>
          )}

          {isDeleted && (
            <Box display="flex" alignItems="center" gap="5px">
              <DeleteIcon />
              <Typography>
                This booking option marked for deletion. Save the class to
                finally remove it.
              </Typography>
            </Box>
          )}
        </Box>

        <Box display="flex">
          <IconButton
            onClick={() => {
              onEditButtonClick();
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsDeleteConfirmationDialogOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
