import { useQuery } from "@apollo/client";
import { Alert, Autocomplete, Box, TextField } from "@mui/material";
import { GetLevelReponse } from "../../graphql/models/responses/get-level-response.type";
import Queries from "../../graphql/queries";
import { Level } from "../../models/level.type";

interface LevelDropdownProps {
  value: Level[];
  onChange: (level: Level[]) => void;

  label?: string;
}

const LevelDropdown = ({ value, onChange, label }: LevelDropdownProps) => {
  const { loading, error, data } = useQuery<GetLevelReponse>(Queries.GetLevels);
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  return (
    <Autocomplete
      loading={loading}
      options={data?.levels || []}
      autoHighlight
      value={value?.[0]}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange([newValue]);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box className="teacher-option" component="li" {...props}>
          <p>{option.name}</p>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : "Choose level"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
};

export default LevelDropdown;
