import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  DialogContentText,
  Fab,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  useGetTeachersPageableLazyQueryType,
  GetTeachersPageableQueryType,
} from "../../__generated___/gql";
import { TeacherOption } from "../../components/teachers/teacher-option/teacher-option";
import { setGlobalErrorNotification } from "../../components/global-notification-overlay/global-notification-overlay";
import { InviteModel } from "../../models/invite.model";

interface EmailInviteDialogData {
  buttonLabel?: string;
  size?: "small" | "medium" | "large";
  onlyByEmail?: boolean;
  onConfirm: (invites: InviteModel[]) => void;
}

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function InviteDialog({
  buttonLabel,
  size = "large",
  onlyByEmail,
  onConfirm,
}: EmailInviteDialogData) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loadTeachers, { loading, error, data }] =
    useGetTeachersPageableLazyQueryType({ fetchPolicy: "network-only" });
  const [pendingInvites, setPendingInvites] = useState<InviteModel[]>([]);
  const [displayValue, setDisplayValue] = useState<
    GetTeachersPageableQueryType["teachers"]
  >([]);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setPendingInvites([]);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (error) {
      setGlobalErrorNotification(error.message);
    }
  }, [error]);

  const fontSizes = {
    small: 10,
    medium: 12.5,
    large: 15,
  };

  return (
    <Box>
      <Fab
        size={size}
        onClick={handleClickOpen}
        variant="extended"
        color="primary"
        aria-label="add"
      >
        <AddIcon fontSize={size} />
        <Typography fontSize={fontSizes[size]}>
          {buttonLabel ?? "Invite Teacher"}
        </Typography>
      </Fab>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Submit invites</DialogTitle>
        <DialogContent>
          {onlyByEmail ? (
            <DialogContentText>
              Input the email address of the person you want to invite in the
              input field below.
            </DialogContentText>
          ) : (
            <DialogContentText>
              Search by name or input an email address of the person you want to
              invite in the input field below.
            </DialogContentText>
          )}

          <Box display="flex" gap="10px" flexDirection="column">
            <Box display="flex" gap="5px" flexWrap="wrap">
              {pendingInvites.map((option, index) => {
                if (option.email) {
                  return (
                    <Chip
                      key={`pending-invite-${index}`}
                      avatar={<Avatar />}
                      label={option.email}
                      onDelete={() => {
                        pendingInvites.splice(index, 1);
                        setPendingInvites([...pendingInvites]);
                      }}
                    />
                  );
                } else {
                  return (
                    <TeacherOption
                      key={`pending-invite-${index}`}
                      teacher={option?.invited_user?.teacher_profile!}
                      onDelete={() => {
                        pendingInvites.splice(index, 1);
                        setPendingInvites([...pendingInvites]);
                      }}
                    />
                  );
                }
              })}
            </Box>

            <Autocomplete
              id="asynchronous-demo"
              fullWidth
              multiple
              open={open}
              value={displayValue}
              onOpen={() => {
                setOpen(true);
                if (!onlyByEmail) {
                  loadTeachers({ variables: { where: {} } });
                }
              }}
              onClose={() => {
                setOpen(false);
              }}
              onBlur={() => {
                setSearchInput("");
              }}
              onChange={(_, newValues) => {
                console.log("onChange", newValues);
                setPendingInvites([
                  ...pendingInvites,
                  ...newValues.map((value) => ({
                    invited_user: { ...value.user, teacher_profile: value },
                  })),
                ]);
              }}
              noOptionsText={
                EMAIL_REGEX.test(searchInput) ? (
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setPendingInvites([
                          ...pendingInvites,
                          { email: searchInput },
                        ]);
                        setSearchInput("");
                        setDisplayValue([]);
                        setOpen(false);
                      }}
                    >
                      Invite by email
                    </Button>
                  </Box>
                ) : (
                  <Typography>Please input a valid email address</Typography>
                )
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={data?.teachers ?? []}
              loading={loading}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, _) => (
                  <Box
                    key={option.id}
                    className="teacher-option-wrapper"
                    component="li"
                  >
                    <TeacherOption teacher={option} />
                  </Box>
                ))
              }
              renderOption={(props, option) => {
                return (
                  <Box
                    key={option.id}
                    className="teacher-option-wrapper"
                    component="li"
                    {...props}
                  >
                    <TeacherOption teacher={option} />
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by name or email"
                  value={searchInput}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(event.target.value);
                    if (!onlyByEmail) {
                      loadTeachers({
                        variables: {
                          where: {
                            _or: [
                              {
                                name: { _ilike: `${event.target.value}%` },
                                user: {
                                  email: { _ilike: `${event.target.value}%` },
                                },
                              },
                            ],
                          },
                        },
                      });
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            flex={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={async () => {
                onConfirm(pendingInvites);
                handleClose();
              }}
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
