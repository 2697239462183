import "./image-input.scss";
import { useState } from "react";
import { FileOrigin, FilePondFile, FileStatus } from "filepond";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import { setGlobalErrorNotification } from "../global-notification-overlay/global-notification-overlay";
import { FileUtils } from "../../lib/utils/file-utils";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

export interface ImageInputProps {
  sources?: string[];
  multiple?: boolean;
  previewRound?: boolean;
  imageSetText?: string;
  imageNotSetText?: string;
  onImagesLoaded: (src: string[]) => void;
  onLoadStart?: () => void;
}

const ImageInput = ({
  sources,
  multiple,
  previewRound,

  onLoadStart,
  onImagesLoaded,
}: ImageInputProps) => {
  // TODO: Replace any with better typing
  const [files, setFiles] = useState<any[]>(
    sources && sources.length > 0
      ? sources.map((source) => ({
          source,
          options: { type: "local" },
        }))
      : []
  );

  const callOnImagesLoaded = (files: FilePondFile[]) => {
    const allInputFilesFinishedProcessing = files
      .filter((file) => file.origin === FileOrigin.INPUT)
      .every((file) => file.status === FileStatus.PROCESSING_COMPLETE);

    if (allInputFilesFinishedProcessing) {
      const uploadedFiles = files.map((file) => file.serverId);
      onImagesLoaded(uploadedFiles);
    }
  };

  return (
    <FilePond
      className={multiple ? "multiple-images" : ""}
      files={[...files]}
      allowBrowse={true}
      allowReorder={multiple}
      allowMultiple={multiple}
      beforeAddFile={() => {
        if (onLoadStart) onLoadStart();
        return true;
      }}
      onerror={(error) => {
        console.log("error", error);
        setGlobalErrorNotification(error.body);
      }}
      onreorderfiles={(files) => {
        callOnImagesLoaded(files);
      }}
      onremovefile={(file) => {
        console.log("onremovefile", file);
      }}
      onprocessfilerevert={(revertedFile) => {
        console.log("onprocessfilerevert", revertedFile);
        setFiles(files.filter((savedFile) => savedFile.id !== revertedFile.id));
      }}
      onprocessfile={(_, file) => {
        if (
          !files
            .map((file) => file.serverId)
            .find((serverId) => file.serverId === serverId)
        ) {
          files.unshift(file);
        }
        callOnImagesLoaded(files);
      }}
      onupdatefiles={(files) => {
        console.log("onupdatefiles", files);
        setFiles(files);
        callOnImagesLoaded(files);
      }}
      acceptedFileTypes={"image/png, image/jpeg, image/heic, image/heif".split(
        ", "
      )}
      imagePreviewHeight={multiple ? 170 : 300}
      imageCropAspectRatio="1:1"
      imageResizeTargetWidth={1024}
      stylePanelLayout={previewRound ? "compact circle" : undefined}
      styleLoadIndicatorPosition="center bottom"
      styleButtonRemoveItemPosition="center bottom"
      labelFileProcessing="Loading..."
      labelFileProcessingComplete="Loaded"
      server={{
        revert: (removedFile) => {
          const uploadedFiles = files.filter(
            (file) =>
              (typeof removedFile === "string" &&
                file.serverId !== removedFile) ||
              file.id !== removedFile.id
          );
          setFiles(uploadedFiles);
          onImagesLoaded(uploadedFiles);
        },
        process: (_0, file, _1, load, error) => {
          FileUtils.readSingleFile(file as File)
            .then((convertedFile) => {
              load(convertedFile);
            })
            .catch((e) => error(e));
        },
        load: (source, load, error) => {
          var myRequest = new Request(source);
          fetch(myRequest)
            .then(function (response) {
              response.blob().then(function (myBlob) {
                load(myBlob);
              });
            })
            .catch((e) => error(e));
        },
      }}
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
    />
  );
};

export default ImageInput;
