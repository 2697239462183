import { useMe } from "../../components/protected-route/protected-route";
import { useVerifyEmailMutation } from "../../__generated___/gql";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../../components/global-notification-overlay/global-notification-overlay";
import { useUrlQuery } from "../../hooks/use-query";

const VerifyEmailCallback = () => {
  const { me } = useMe();
  const urlQuery = useUrlQuery();
  const navigate = useNavigate();
  const code = urlQuery.get("code") || undefined;
  const [verifyEmailMutation, { data: verifyEmailMutationData }] =
    useVerifyEmailMutation();

  useEffect(() => {
    verifyEmailMutation({ variables: { code: code ?? "" } });
  }, []);

  useEffect(() => {
    if (verifyEmailMutationData) {
      const { verify_email: isVerified } = verifyEmailMutationData;
      if (isVerified) {
        setGlobalSuccessNotification("Successfully verified your email");
      } else {
        setGlobalErrorNotification("Email verify failed.");
      }
      setTimeout(() => {
        navigate(`/app/teachers/${me?.teacher_profile?.id}?refetchMe=true`);
      }, 1000);
    }
  }, [verifyEmailMutationData]);

  return <LoadingOverlay />;
};

export default VerifyEmailCallback;
