export default class Fragments {
  public static Teacher = `
  id
  name
  description
  location_name
  instagram_name
  is_organization
  user_id
  is_stripe_enabled
  stripe_id
  community {
    id
    name
    location
    longitude
    latitude
  }
  teacher_levels {
    level {
      id
      name
    }
  }
  user {
    id
    name
    image_url
  }
  images {
    id
    is_profile_picture
    image {
      url
    }
  }
  `;

  public static ClassEvent = `
  id
  start_date
  end_date
  is_cancelled
  class {
    id
    name
  }

  is_expired @client
  `;

  public static Role = `
  id
  name
  `;

  public static User = `
  id
  image_url
  fcm_token
  bio
  created_at
  name
  is_admin
  used_teacher_signup
  teacher_profile {
    ${Fragments.Teacher}
  }
  user_roles {
    role {
      ${Fragments.Role}
    }
  }
  `;
}
