import { Box } from "@mui/material";
import { CreateTeacherForm } from "../../screens/teachers/create-teacher-form/create-teacher-form";

interface SetUpTeacherProfileProps {
  userId: string;
}
const SetUpTeacherProfile = ({ userId }: SetUpTeacherProfileProps) => {
  return (
    <Box>
      <CreateTeacherForm
        userId={userId}
        isIntroductionForm={true}
        onFinish={() => {
          window.location.reload();
        }}
      />
    </Box>
  );
};

export default SetUpTeacherProfile;
