import { useMe } from "../../components/protected-route/protected-route";
import { useVerifyStripeAccountMutation } from "../../__generated___/gql";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from "../../components/global-notification-overlay/global-notification-overlay";
import { useUrlQuery } from "../../hooks/use-query";

const StripeCallback = () => {
  const { me } = useMe();
  const urlQuery = useUrlQuery();
  const reCheck = urlQuery.get("reCheck") || undefined;
  const navigate = useNavigate();
  const [
    verifyStripeAccountMutation,
    { data: verifyStripeAccountMutationData },
  ] = useVerifyStripeAccountMutation();

  useEffect(() => {
    verifyStripeAccountMutation();
  }, []);

  useEffect(() => {
    if (verifyStripeAccountMutationData) {
      const { verify_stripe_account: isVerified } =
        verifyStripeAccountMutationData;
      if (reCheck) {
        if (isVerified) {
          setGlobalSuccessNotification(
            "Successfully set up your stripe account"
          );
        } else {
          setGlobalErrorNotification("Stripe setup failed. Please try again");
        }
      }

      setTimeout(() => {
        navigate(`/app/teachers/${me?.teacher_profile?.id}?refetchMe=true`);
      }, 1000);
    }
  }, [verifyStripeAccountMutationData]);

  return <LoadingOverlay />;
};

export default StripeCallback;
